import {baseUrl} from "../global";
import {toaster} from "evergreen-ui";

const toggleUserActivation = (
    id,
    status,
    auth,
    cb
) => {
    const statusRes = fetch(`${baseUrl}/admin/users/${id}?status=${status}`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
        }
    })

    statusRes.then(res => {
        toaster.success("Status updated.");
        res.json().then(res => cb())
    }).catch(e => {
        console.error(e)
    })
}

export { toggleUserActivation }
