import types from './types'

export const setFileQueue = (fileQueue) => ({
  type: types.SET_FILE_QUEUE,
  payload: fileQueue
})

export const addToFileQueue = (fileUpload) => ({
  type: types.ADD_TO_FILE_QUEUE,
  payload: fileUpload
})

export const removeFromFileQueue = () => ({
  type: types.REMOVE_FROM_FILE_QUEUE,
  payload: null
})

export const updateLastUploadedFile = (videoUpload) => ({
  type: types.UPDATE_LAST_UPLOADED_FILE,
  payload: videoUpload
})