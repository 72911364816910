import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import "./plan-details.styles.scss";
import { ReactComponent as LargeSub } from "../../../../assets/icons/large-sub-icon.svg";
import {connect} from "react-redux";
import Api from "../../../../utils/action";
import SearchInput from "../../../../components/general/search-input/search-input";
import {generateTableCol} from "../../../../utils/helper";
import {formatDate} from "../../../../utils/global";
import DashboardTable from "../../../../components/general/dashboard-table/dashboard-table";
import {TopSectionNav} from "../../../../components/top-section-nav/top-section-nav";
import {toaster} from "evergreen-ui";
import {useQuery} from "react-query";

const PlanDetails = ({ currentAdmin }) => {
    const { id } = useParams()
    const history = useHistory()
    const [detail, switchDetail] = useState("users");
    const [planDetails, setPlanDetails] = useState(null)
    const [usersTable, setUsersTable] = useState(null)

    const { isLoading, data } = useQuery(["plan", currentAdmin.token, id], () => Api.plans.fetchPlan(currentAdmin.token, id), {
        keepPreviousData: true,
    });
    
    useEffect(() => {
        if (!isLoading) {
            if (data.status) setPlanDetails(data.data)
            else {
                toaster.danger(data.message)
                history.push('/dashboard/subscriptions')
            }
        }
    }, [isLoading, data, history])

    useEffect(() => {
        if (planDetails) {
            const tempUsersTable = {
                id: 'plan-users-table',
                columns: [
                    generateTableCol('Name', 'full_name'),
                    generateTableCol('Subscription Start Date', 'start_date'),
                    generateTableCol('Expiry Date', 'exp_date'),
                ],
                data: planDetails['users'].map(user => {
                    user.full_name = `${user.first_name} ${user.last_name}`
                    user.start_date = formatDate(new Date(user['created_on']), true)
                    user.exp_date = formatDate(new Date(user['modified_on']), true)

                    return user
                })
            }

            setUsersTable(tempUsersTable)
        }
    }, [planDetails])

    const updatePlanStats = (status) => console.log(status)

    return (
        <div className="plan_details">
            <TopSectionNav
                path="/dashboard/subscriptions"
                header="Subscription"
                back="Back to Subscriptions"
            />
            <SearchInput />

            <div className="section-title-cover">
                <div className="section-title-block">
                    <div className="img">
                        <LargeSub />
                    </div>
                    <p>{ planDetails && `${planDetails.name.toUpperCase()} PLAN` }</p>
                </div>

                {
                    planDetails &&
                    false &&
                    <div className="header-buttons">
                        {!planDetails['activated'] ? (
                            <button className="deactivate-btn" onClick={() => updatePlanStats(false)}>DEACTIVATE</button>
                        ) : (
                            <button className="activate-btn" onClick={() => updatePlanStats(true)}>ACTIVATE</button>
                        )}
                    </div>
                }
            </div>

            <div className="dashboard-card">
                <div className="dashboard-card dashboard-tab-view">
                    <div className="dashboard-tab-heading">
                        <div className="dashboard-tab-menu">
                          <span
                              onClick={() => switchDetail("users")}
                              className={`tab-menu-item${detail === "users" ? " active" : ""}`}
                          >
                            USERS
                          </span>
                        </div>
                    </div>
                    <div className="dashboard-tab-body">
                        <div className="dashboard-table-cover">
                            {
                                detail === "users" &&
                                usersTable &&
                                <DashboardTable
                                    columns={usersTable.columns}
                                    data={usersTable.data}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ user }) => ({
    currentAdmin: user.currentAdmin,
})

export default connect(mapStateToProps)(PlanDetails);
