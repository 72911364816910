import {CustomInput, CustomTextarea} from "../form-elements/custom-input";
import {Spinner, toaster} from "evergreen-ui";
import React from "react";
import RolesApi from "../../utils/action/role";
import Api from "../../utils/action";

const RoleModal = (
    {
        addRoleCb,
        closeModal,
        permissions,
        auth,
        role,
        updateRole,
    }
) => {
    const [actionRunning, setActionRunning] = React.useState(false);

    const updateName = (e) => updateRole({ ...role, name: e.target.value })
    const updateDescription = (e) => updateRole({ ...role, description: e.target.value })
    const updatePermissions = () => {
        let selectedPermissionsElems = document.querySelectorAll('input[name="permissions"]:checked')
        const selectedPermissions = [...selectedPermissionsElems].map(e => parseInt(e.value))
        updateRole({ ...role, permissions: selectedPermissions })
    }

    // Save Role
    const saveRole = () => {
        if (actionRunning) return
        else setActionRunning(true)

        const data = {
            name: role.name,
            description: role.description,
            permissions: role.permissions
        }

        if (role.id) {
            Api.role.updateRole(auth, role.id, data, async (res) => {
                if (res.status) {
                    toaster.success('Role Updated')
                    addRoleCb(res)
                    setActionRunning(false)
                }
            })
        } else {
            Api.role.addRole(auth, data, async (res) => {
                if (res.status) {
                    toaster.success('Role Added')
                    addRoleCb(res)
                    setActionRunning(false)
                }
            })
        }
    }

    return (
        <div className="modal settings-modal role-modal">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title">
                            {
                                role.id ?
                                    'UPDATE ROLE' :
                                    'ADD A ROLE'
                            }
                        </p>
                        <div className="close-btn" onClick={closeModal}>
                            <span>X</span>
                        </div>
                    </div>
                    <div className="modal-body">
                        <CustomInput id="role-name" label="Name" value={role.name} onChange={updateName} />
                        <CustomTextarea id="description" label="Description" value={role.description} onChange={updateDescription} />
                        <div className="permission-groups">
                            {
                                permissions.map(group => (
                                  <div className="check-group-cover" key={group.id}>
                                      <label className="group-label">{group.name}</label>
                                      <div className="checkboxes">
                                          {
                                              group.permissions.map(permission => (
                                                <div className="check-group" key={`${group.id}-${permission.id}`}>
                                                    <label>
                                                        <input type="checkbox" value={permission.id} name="permissions" onChange={updatePermissions} checked={role.permissions.includes(permission.id)} />
                                                        {permission.name}
                                                    </label>
                                                </div>
                                              ))
                                          }
                                      </div>
                                  </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        {
                            !actionRunning ? (
                                <button className="btn-primary submit-btn" onClick={() => saveRole()}>
                                    {
                                        role.id ?
                                            'UPDATE' :
                                            'ADD'
                                    }
                                </button>
                            ) : (<Spinner size={16} className="spinner"/>)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RoleModal