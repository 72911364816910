import {baseUrl} from "../global";
import {toaster} from "evergreen-ui";

const updatePassword = (auth, data) => {
    const addRes = fetch(`${baseUrl}/admin/auth/password`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
        }
    })

    addRes.then(res => {
        res.json().then(res => {
            if (res.status) {
                toaster.success("Password Updated successfully")
            } else toaster.danger(res.message)
        })
    }).catch(e => {
        toaster.danger(e.message)
        console.error(e)
    })
}

const Account = {updatePassword}
export default Account