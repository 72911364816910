import FilterIC from "../../assets/icons/filter-ic.svg";
import {CustomInput, CustomSelectInput} from "../form-elements/custom-input";
import React, {useState} from "react";
import produce from "immer";
import {generateItemN} from "../../utils/helper";
import {capitalize} from "../../utils/global";

const TransactionsFilter = ({ filters, setFilters, filterRes })  => {

  const [typeDropdown, setTypeDropdown] = useState({
    id: 'type-filter-select',
    title: 'All',
    items: [
      generateItemN({text: 'All', value: ''}),
      generateItemN({text: 'Web', value: 'web'}),
      generateItemN({text: 'Mobile', value: 'mobile'}),
    ]
  });

  const [statusDropdown, setStatusDropdown] = useState({
    id: 'status-filter-select',
    title: 'All',
    items: [
      generateItemN({text: 'All', value: ''}),
      generateItemN({text: 'Success', value: 'success'}),
      generateItemN({text: 'Pending', value: 'pending'}),
      generateItemN({text: 'Failed', value: 'failed'}),
    ]
  });

  const toStr = (val) => {
    if (val === '') return 'All'
    else return capitalize(val)
  }

  const updateFilters = (value, key) => {
    setFilters(filters => produce(filters, data => { data[key] = value }))
  }

  const reset = () => {
    setFilters({ reference: '', type: '', status: '', email: '', amount: '', from: '', to: ''})
  }

  return (
    <div className="dropdown filter" id="transactions-dropdown">
      <div className="dropdown-toggle filter-toggle" id="transactions-dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
        <div>FILTER BY</div>
        <div className="dropdown-arrow-down">
          <img src={FilterIC} alt="filter-ic" />
        </div>
      </div>
      <div className="dropdown-menu filter-menu" aria-labelledby="#transactions-dropdown-toggle">
        <div className="">
          <CustomInput
            id="reference"
            type="text"
            label="Reference"
            value={filters.reference}
            handleChange={(e) => updateFilters(e.target.value, 'reference')}
          />
        </div>
        <div className="">
          <CustomSelectInput
            id="type"
            dropDown={typeDropdown}
            label="Type"
            selected={{ value: filters.type, text: toStr(filters.type) }}
            onChange={(item) => updateFilters(item.value, 'type')}
          />
        </div>
        <div className="">
          <CustomSelectInput
            id="status"
            dropDown={statusDropdown}
            label="Status"
            selected={{ value: filters.status, text: toStr(filters.status) }}
            onChange={(item) => updateFilters(item.value, 'status')}
          />
        </div>
        <div className="">
          <CustomInput
            id="amount"
            type="number"
            label="Amount"
            value={filters.amount}
            handleChange={(e) => updateFilters(e.target.value, 'amount')}
          />
        </div>
        <div className="">
          <CustomInput
            id="email"
            type="email"
            label="Email"
            value={filters.email}
            handleChange={(e) => updateFilters(e.target.value, 'email')}
          />
        </div>
        <div className="">
          <CustomInput
            id="from"
            type="date"
            label="From"
            value={filters.from}
            handleChange={(e) => updateFilters(e.target.value, 'from')}
          />
        </div>
        <div className="">
          <CustomInput
            id="to"
            type="date"
            label="to"
            value={filters.to}
            handleChange={(e) => updateFilters(e.target.value, 'to')}
          />
        </div>
        <div className="row action-buttons">
          <div className="col-md-6">
            <button type="button" className="btn-primary reset-btn" onClick={reset}>
              RESET
            </button>
          </div>
          <div className="col-md-6">
            <button type="button" className="btn-primary" onClick={() => filterRes(true)}>
              FILTER
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransactionsFilter