import ApiCalls from "./api";

const Plans = {
    fetchPlans: async (auth) => await ApiCalls.getForQuery(auth, 'admin/plans', 0),
    fetchPlansList: (auth) => ApiCalls.getFetchListAsync(auth, 'admin/dashboard/plans', []),
    fetchPlan: async (auth, id) => await ApiCalls.getById(auth, 'admin/plans', id),
    create: async (auth, data) => await ApiCalls.postForResAsync(auth, 'admin/plans', data),
    update: async (auth, id, data) => await ApiCalls.patchForResAsync(auth, `admin/plans/${id}`, data),
    deletePlan: async (auth, id) => await ApiCalls.deleteReqAsync(auth, `admin/plans`, id),
}
export default Plans