import React, {useEffect, useState} from "react";
import SectionNav from "../../../components/landing-section-nav/landing-section-nav";
import {connect} from "react-redux";
import Paginate from "../../../components/paginate/paginate";
import {formatActivity, perPage} from "../../../utils/global";
import DashboardTable from "../../../components/general/dashboard-table/dashboard-table";
import Api from "../../../utils/action";
import {setLoading} from "../../../redux/dashboard/actions";
import ActivitiesFilter from "../../../components/activities/activities-filter";

const Activity = ({ currentAdmin, setLoading }) => {
  const [activities, setActivities] = React.useState([]);
  const [activitiesFilters, setActivitiesFilter] = useState({ first_name: '', last_name: '', email: '', from: '', to: ''})

  const [activitiesTable, setActivitiesTable] = useState({
    cols: [
      {title: 'Date', classes: 'no-wrap', key: 'date'},
      {title: 'User', classes: 'no-wrap', key: 'fullName'},
      {title: 'Email', classes: 'no-wrap', key: 'owner_email'},
      {title: 'Description', classes: '', key: 'description'},
    ],
    data: []
  });

  const [pagination, setPagination] = useState({
    total: 1,
    totalItems: 1,
    page: 0,
    perPage,
  })

  const fetchActivities = async (reset = false) => {
    setLoading(true)

    const page = reset ? 0 : pagination.page
    const res = await Api.activities.getActivities(currentAdmin.token, page, activitiesFilters)
    if (res.status) {
      setActivities(res.data.result)
      setPagination(pagination => ({
        ...pagination,
        total: res.data.page_info.total_pages,
        totalItems: res.data.page_info.total,
        page,
      }))
    }

    setLoading(false)
  }

  const setPage = (page) => {
    setPagination(pagination => ({ ...pagination, page: page }))
  }

  useEffect(() => {
    fetchActivities().then(() => {}).catch(e => console.error(e))
  }, [currentAdmin.token, pagination.page])

  useEffect(() => {
    setActivitiesTable(table => ({
      ...table,
      data: formatActivity(activities)
    }))
  }, [activities])

  return (
      <div className="activity">
        <SectionNav header="Activities" />

        <div className="col-md-4">
          <ActivitiesFilter
            filters={activitiesFilters}
            setFilters={setActivitiesFilter}
            filterRes={fetchActivities}
          />
        </div>

        <div className="dashboard-card">
          <DashboardTable columns={activitiesTable.cols} data={activitiesTable.data} />
        </div>

        <Paginate
          currentPage={pagination.page}
          totalPages={pagination.total}
          setPage={setPage}
        />
      </div>
  );
};

const mapStateToProps = ({ user }) => ({
  currentAdmin: user.currentAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
