import {baseUrl} from "../global";
import {toaster} from "evergreen-ui";
import ApiCalls from "./api";

const Role = {
    addRole: (auth, data, cb) => ApiCalls.postForRes(auth, 'admin/roles', data, cb),
    updateRole: (auth, id, data, cb) => ApiCalls.patchForRes(auth, `admin/roles/${id}`, data, cb),
    fetchRoles: (auth) => ApiCalls.getFetchListAsync(auth, 'admin/roles'),
    deleteRole: (auth, id, cb) => ApiCalls.deleteReq(auth, 'admin/roles', id, cb)
}

export default Role