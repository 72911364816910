import React from "react";
import "./login-layout.styles.scss";
import Dyekaadmin from "../../assets/images/dyeka-illustration.png";

export const LoginLayout = ({ heading, name }) => {
  return (
      <div className="login_layout">
          <div className="login_bg">
              <p className="login_bg_head">{heading}</p>
              <p className="access">{name}</p>
          </div>
          <div className="login_svg">
              <img src={Dyekaadmin} alt="auth-bg" className="admin" />
          </div>
      </div>
  );
};
