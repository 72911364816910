import React, {useEffect, useState} from "react";
import TutorIc from "../../../assets/icons/tutor-ic.svg";
import ClockIc from "../../../assets/icons/clock-ic.svg";
import LiveIC from "../../../assets/icons/live-ic.svg";
import ToggleVideo from "../../../assets/icons/toggle-video-ic.svg";
import ToggleAudio from "../../../assets/icons/toggle-audio.svg";
import OT from '@opentok/client'
import {timeTo12HourFormat} from "../../../utils/global";

const SetupLivestream = (
  {livestream, updateStage, initStream, sessionConnected, videoControl, toggleAudio, toggleVideo}
) => {
    useEffect(() => {
        if (sessionConnected) {
            const setupPublisher = OT.initPublisher('setup-video', {
                insertMode: 'append',
                width: '100%',
                height: '100%'
            }, (e) => console.error(e ? e : ''));

            initStream(setupPublisher)
        }
    }, [sessionConnected])

    return (
        <div className="start-livestream-page init-livestream">
            <h3 className="intro-text text-center">Welcome to this <span>LIVE STREAM</span></h3>
            <div className="row details">
                <div className="col-auto">
                    <div className="detail row mx-1">
                        <div className="col-auto icon">
                            <img src={ClockIc} alt='tutor-ic' />
                        </div>
                        <p className="col">{ timeTo12HourFormat(livestream.event_start_time) } - { timeTo12HourFormat(livestream.event_close_time) }</p>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="detail row mx-1">
                        <div className="col-auto icon">
                            <img src={TutorIc} alt='tutor-ic' />
                        </div>
                        <p className="col">{ livestream.tutor_name }</p>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-8 mx-auto">
                    <div className="video-player-cover">
                        <div className="timeline row">
                            <div className="tm-icon col-auto">
                                <img src={LiveIC} alt="live-ic" />
                            </div>
                            <p className="col-auto">Live</p>
                            <p className="col-auto timestamp text-muted">00:00:00</p>
                        </div>
                        <div className="video-player" id="setup-video">
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-8 mx-auto">
                    <div className="video-player-controls row justify-content-center">
                        <div className="col-auto">
                            <div className="control-cover">
                                <div className="control" onClick={toggleVideo}>
                                    <img src={ToggleVideo} alt="control" />
                                    {
                                        !videoControl.video &&
                                        <div className="disabled" />
                                    }
                                </div>
                                <p>Video</p>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="control-cover">
                                <div className="control" onClick={toggleAudio}>
                                    <img src={ToggleAudio} alt="control" />
                                    {
                                        !videoControl.audio &&
                                        <div className="disabled" />
                                    }
                                </div>
                                <p>Audio</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="action-btn row mt-5 mb-2">
                <button className="btn btn-primary col-auto mx-auto" onClick={updateStage}>
                    START LIVESTREAM
                </button>
            </div>
        </div>
    )
}

export default SetupLivestream