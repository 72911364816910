import FilterIC from "../../assets/icons/filter-ic.svg";
import {CustomInput, CustomSelectInput} from "../form-elements/custom-input";
import React, {useEffect, useState} from "react";
import produce from "immer";
import {generateItemN} from "../../utils/helper";
import {capitalize} from "../../utils/global";

const TeammatesFilter = ({ filters, setFilters, filterRes, roles })  => {

  const [roleDropdown, setRoleDropdown] = useState({
    id: 'role-filter-select',
    title: 'All',
    items: []
  });

  const toStr = (val) => {
    if (val === '') return 'All'
    else return capitalize(val)
  }

  const updateFilters = (value, key) => {
    setFilters(filters => produce(filters, data => { data[key] = value }))
  }

  const reset = () => {
    setFilters({ role_name: '', first_name: '', last_name: '', email: ''})
  }

  useEffect(() => {
    const items = [
      generateItemN({text: 'All', value: ''}),
      ...roles.map(role => generateItemN({ text: toStr(role.name), value: role.name })),
    ]

    setRoleDropdown(dropdown => {
      dropdown.items = items
      return dropdown
    })
  }, [roles])

  return (
    <div className="dropdown filter" id="teammates-filter-dropdown">
      <div className="dropdown-toggle filter-toggle" id="teammates-filter-dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
        <div>FILTER BY</div>
        <div className="dropdown-arrow-down">
          <img src={FilterIC} alt="filter-ic" />
        </div>
      </div>
      <div className="dropdown-menu filter-menu" aria-labelledby="#teammates-filter-dropdown-toggle">
        <div className="">
          <CustomInput
            id="first-name"
            type="text"
            label="First Name"
            value={filters.first_name}
            handleChange={(e) => updateFilters(e.target.value, 'first_name')}
          />
        </div>
        <div className="">
          <CustomInput
            id="last-name"
            type="text"
            label="Last Name"
            value={filters.last_name}
            handleChange={(e) => updateFilters(e.target.value, 'last_name')}
          />
        </div>
        <div className="">
          <CustomSelectInput
            id="role-filter"
            dropDown={roleDropdown}
            label="Role"
            selected={{ value: filters.role_name, text: toStr(filters.role_name) }}
            onChange={(item) => updateFilters(item.value, 'role_name')}
          />
        </div>
        <div className="">
          <CustomInput
            id="email"
            type="email"
            label="Email"
            value={filters.email}
            handleChange={(e) => updateFilters(e.target.value, 'email')}
          />
        </div>
        <div className="row action-buttons">
          <div className="col-md-6">
            <button type="button" className="btn-primary reset-btn" onClick={reset}>
              RESET
            </button>
          </div>
          <div className="col-md-6">
            <button type="button" className="btn-primary" onClick={() => filterRes(true)}>
              FILTER
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeammatesFilter