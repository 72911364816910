import {CustomInput, CustomTextarea} from "../form-elements/custom-input";
import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import produce from "immer";
import {setLoading} from "../../redux/dashboard/actions";
import Api from "../../utils/action";
import {toaster} from "evergreen-ui";

const SavePlanForm = ({auth, setLoading, plan, setPlan, savedPlan}) => {

  const [errors, setErrors] = useState({
    name: '',
    profiles: '',
  })

  const updatePlan = (key, value) => {
    setPlan(plan => produce(plan, data => {
      data[key] = value
    }))
  }

  const validatePlan = (data) => {
    return true
  }

  const savePlan = async () => {
    setLoading(true)

    const {name, profiles, devices, monthly_amount, yearly_amount, description} = plan
    const data = {name, profiles, devices, monthly_amount, yearly_amount, description}

    if (!validatePlan(data)) return

    try {
      const res = plan.id ? await Api.plans.update(auth, plan.id, data) : await Api.plans.create(auth, data)
      if (res.status) savedPlan(res.message)
      else toaster.danger(res.message)
    } catch (e) {
      console.error(e)
    }

    setLoading(false)
  }

  return (
    <div className="plan_form">
      {
        plan &&
        <form>
          <h1>{plan.id ? 'EDIT SUBSCRIPTION' : 'ADD A SUBSCRIPTION PLAN'}</h1>
          <div className="input_flex">

            <CustomInput
              id="name"
              name="name"
              type="text"
              placeholder=""
              label="Name"
              value={plan.name}
              onChange={(e) => updatePlan('name', e.target.value)}
            />
            <CustomInput
              id="profiles"
              name="profiles"
              type="number"
              label="Profiles"
              value={plan.profiles}
              onChange={(e) => updatePlan('profiles', e.target.value)}
            />
          </div>

          <div className="input_flex">
            <CustomInput
              id="monthly-amount"
              name="m_amount"
              type="number"
              label="Monthly Amount"
              value={plan.monthly_amount}
              onChange={(e) => updatePlan('monthly_amount', e.target.value)}
            />

            <CustomInput
              id="yearly-amount"
              name="y_amount"
              type="number"
              label="Yearly Amount"
              value={plan.yearly_amount}
              onChange={(e) => updatePlan('yearly_amount', e.target.value)}
            />
          </div>

          <div className="input_flex">
            <CustomInput
              id="devices"
              name="devices"
              type="number"
              label="Devices"
              value={plan.devices}
              onChange={(e) => updatePlan('devices', e.target.value)}
            />

            <CustomTextarea
              id="description"
              name="description"
              type="text"
              placeholder=""
              label="Description"
              value={plan.description}
              onChange={(e) => updatePlan('description', e.target.value)}
            />
          </div>

          <div className="input_flex">
            <div className='action-btn-cover'>
              <button type="button" className="btn-primary submit-btn" onClick={savePlan}>
                PUBLISH
              </button>
            </div>
          </div>
        </form>
      }
    </div>
  )
}

const mapStateToProps = ({ user }) => ({
  auth: user.currentAdmin.token
})

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SavePlanForm))