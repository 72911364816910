import React, { useState } from "react";
import logo from "../../assets/images/dyeka-logo-4a.png";
import { LoginLayout } from "../../components/Login-layout/login-layout.component";
import Button from "../../components/custom-button/custom-button.component";
import "./reset-password.styles.scss";
import { connect } from "react-redux";
import { PasswordReset } from "../../redux/admin-user/admin.actions";
import FormPasswordInput from "../../components/form-password-input/form-password-input";
import { Formik } from "formik";
import * as Yup from "yup";
import { toaster, Spinner } from "evergreen-ui";
import FormInput from "../../components/form-input/form-input";
import {Link} from "react-router-dom";
import {baseUrl} from "../../utils/global";

const ResetPasswordSchema = Yup.object().shape({
  otp: Yup.number().required("OTP is required"),

  password: Yup.string().required("Password is required"),
  // .min(4, "Password is too short - should be 4 chars minimum"),

  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

const ResetPassword = ({ PasswordReset, history }) => {
  const [emailAddress, setEmailaddress] = useState("");

  const [otpMessage, updateOtpMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const handleEmail = (event) => {
    setEmailaddress(event.target.value);
  };

  const handleEmailSubmit = async (event) => {
    event.preventDefault();

    // console.log(emailAddress);

    fetch(`${baseUrl}/admin/auth/password`, {
      method: "PATCH",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({
        email: emailAddress,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        toaster.success(`${data.message}`);

        updateOtpMessage(data.message);
      });
  };

  const submitForm = (values) => {
    // console.log(values, emailAddress);
    setLoading(true);

    const { otp, password } = values;
    fetch(`${baseUrl}/admin/auth/password`, {
      method: "PUT",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({
        email: emailAddress,
        otp: otp.toString(),
        password,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        PasswordReset(data.status);
        setLoading(false);

        if (data.status === true) {
          toaster.success("Succesfully reset password");
          history.push("/");
        } else {
          toaster.danger(`${data.message}`);
        }
      });
  };

  return (
    <div className="auth-cover reset-password">
      <div className="auth-background">
        <LoginLayout
          heading="Admin Dashboard"
          name="Forgot your password? Reset password"
        />
      </div>

      <div className="auth-form-cover">
        <div className="auth-form">
          <div className="auth-heading-cover">
            <h3 className="auth-form-heading">
              <span className="admin">Reset Admin Password</span>
              <span>
              <img src={logo} alt="dyeka admin logo" />
            </span>
            </h3>
            <p className="auth-form-intro">
            </p>
          </div>

          <form className="otp-form" onSubmit={handleEmailSubmit}>
            <div className="otp-div">
              <FormInput
                  name="email"
                  onChange={handleEmail}
                  value={emailAddress}
                  label="Email Address"
                  type="email"
              />
              <Button>SEND OTP TO EMAIL</Button>
            </div>
          </form>

          <Formik
              initialValues={{
                // email: emailAddress,
                otp: "",
                password: "",
                confirmPassword: "",
              }}
              onSubmit={submitForm}
              validationSchema={ResetPasswordSchema}
          >
            {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched,
                dirty,
                isValid,
              }) => (
                <form className="reset-form" onSubmit={handleSubmit}>
                  <FormInput
                      name="otp"
                      onChange={handleChange}
                      value={values.otp}
                      label="OTP (Check your email)"
                      onBlur={handleBlur}
                      type="number"
                  />
                  <FormPasswordInput
                      name="password"
                      handleChange={handleChange}
                      value={values.password}
                      label="New Password"
                      onBlur={handleBlur}
                      errorText={
                        errors.password && touched.password
                            ? `${errors.password}`
                            : null
                      }
                  />
                  <FormPasswordInput
                      name="confirmPassword"
                      handleChange={handleChange}
                      value={values.confirmPassword}
                      label="Re-enter Password"
                      onBlur={handleBlur}
                      errorText={
                        errors.confirmPassword && touched.confirmPassword
                            ? `${errors.confirmPassword}`
                            : null
                      }
                  />


                  <p className="forgot-password">
                    <Link to="/">Back to login</Link>
                  </p>

                  <Button type="submit" disabled={!(dirty && isValid)}>
                    {isLoading && <Spinner size={16} className="spinner" />}
                    <span> RESET PASSWORD</span>
                  </Button>
                </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  PasswordReset: (message) => dispatch(PasswordReset(message)),
});

export default connect(null, mapDispatchToProps)(ResetPassword);
