import "./view-livestream.scss"

import {useHistory, useParams, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {TopSectionNav} from "../../../../components/top-section-nav/top-section-nav";
import SetupLivestream from "../../../../components/livestream/livestream-view/setup-livestream";
import StartLivestream from "../../../../components/livestream/livestream-view/start-livestream";
import EndLivestream from "../../../../components/livestream/livestream-view/end-livestream";
import Api from "../../../../utils/action";
import OT from '@opentok/client'
import {toaster} from "evergreen-ui";
import {destroySession, startSession} from '../../../../redux/livestream/actions'
import {setLoading} from "../../../../redux/dashboard/actions";
import produce from "immer";

const ViewLivestream = ({currentAdmin, session, startSession, destroySession, setLoading}) => {

  const history = useHistory()
  const {id} = useParams()
  const [stage, setStage] = useState('')
  const [streamSession, setStreamSession] = useState(null)
  const [livestream, setLivestream] = useState(null)
  const [sessionConnected, setSessionConnected] = useState(false)
  const [activePublisher, setActivePublisher] = useState(null)
  const [videoControl, setVideoControl] = useState({
    video: true,
    audio: true,
  })
  const [messages, setMessages] = useState([])
  const [members, setMembers] = useState([])
  const sessionsRef = useRef()
  const activePublisherRef = useRef()

  useEffect(() => {
    sessionsRef.current = session
  }, [session])

  useEffect(() => {
    activePublisherRef.current = activePublisher
  }, [activePublisher])

  const initializeStream = async () => {
    const res = await Api.livestreams.initializeLivestream(currentAdmin.token, id)
    if (res.status) return res.data
  }

  const fetchLivestream = async () => {
    const res = await Api.livestreams.fetchLivestream(currentAdmin.token, id)
    if (res.status) return res.data
  }

  const handleError = (error) => {
    if (error) {
      alert(error.message);
    }
  }

  const initializeSession = (streamSession) => {
    const session = OT.initSession(streamSession.apiKey, streamSession.sessionId)

    session.on('streamCreated', function (event) {
      const stream = event.stream
      const streamData = stream.connection.data

      const member = {
        id: stream.connection.id,
        name: streamData.split('=')[1],
        stream
      }

      setMembers(members => produce(members, data => {
        data.push(member)
      }))
    });

    session.on('streamDestroyed', function (event) {
      const stream = event.stream
      const streamId = stream.connection.id

      try {
        setMembers(members => produce(members, data => {
          const findIndex = data.findIndex(member => member.id === streamId)
          data.splice(findIndex, 1)
        }))
      } catch (e) {
        console.error(e.message)
      }
    });

    session.on('signal:msg', function (event) {
      const data = JSON.parse(event.data)

      const msg = {
        ...data,
        mine: event.from.connectionId === session.connection.connectionId,
        timestamp: data.timestamp ? data.timestamp : new Date()
      }

      setMessages(messages => produce(messages, data => {
        data.push(msg)
      }))
    });

    session.connect(streamSession.token, async function (error) {
      if (error) handleError(error)
      else {
        setSessionConnected(true)
      }
    });

    startSession(session)
  }

  const toggleVideo = async () => {
    if (activePublisher) {
      activePublisher.publishVideo(!videoControl.video)
      setVideoControl({...videoControl, video: !videoControl.video})
    }
  }

  const toggleAudio = async () => {
    if (activePublisher) {
      activePublisher.publishAudio(!videoControl.audio)
      setVideoControl({...videoControl, audio: !videoControl.audio})
    }
  }

  useEffect(() => {
    async function init() {
      const streamSession = await initializeStream()
      const livestream = await fetchLivestream()

      if (livestream && streamSession) {
        setStreamSession(streamSession)
        setLivestream(livestream)
      } else {
        toaster.danger('An error occurred')
        history.push(`/dashboard/livestream`)
      }
    }

    init().then().catch(e => console.error(e))
  }, [id])

  useEffect(() => {
    if (livestream && streamSession) {
      initializeSession(streamSession)
    }
  }, [livestream, streamSession])

  useEffect(() => {
    async function setup() {
      if (livestream) {
        if (livestream.status.toLowerCase() === 'done') {
          setStage('end-video')
        } else if (livestream.status.toLowerCase() === 'started') {
          setStage('start-video')
        } else if (streamSession.token && streamSession.sessionId) {
          await setStage('setup-video')
        } else if (livestream.status === 'PENDING') {
          toaster.danger('Please initialize stream first')
          history.push(`/dashboard/livestream/${id}`)
        }
      }
    }

    if (session) setup().then().catch(e => console.error(e))
  }, [session, livestream])

  const publishVideo = (publisher) => {
    if (sessionConnected) {
      if (activePublisher) {
        session.unpublish(activePublisher)
        activePublisher.destroy()
      }

      setActivePublisher(publisher)
      session.publish(publisher, streamSession.token)
    }
  }

  const initStream = async (publisher) => {
    publishVideo(publisher)
  }

  const initStartStream = async () => {
    const res = await Api.livestreams.startLivestream(currentAdmin.token, id)
    if (res.status) setStage('start-video')
  }

  const startStream = async (publisher) => {
    publishVideo(publisher)
  }

  const endLivestream = async () => {
    const res = await Api.livestreams.endLivestream(currentAdmin.token, id)
    if (res.status) {
      setStage('end-video')
      initDestroySession()
      destroySession()
    }
  }

  const initDestroySession = () => {
    if (activePublisherRef && sessionsRef && activePublisherRef.current && sessionsRef.current) {
      sessionsRef.current.unpublish(activePublisherRef.current)
      activePublisherRef.current.destroy()
      sessionsRef.current.disconnect()
    }
  }

  useEffect(() => {
    // setLoading(true)

    return function () {
      initDestroySession()
    }
  }, [])

  return (
    <div className="view-livestream-page">
      <TopSectionNav
        path={`/dashboard/livestream/${id}`}
        header="Livestream View"
        back="Back to Live Stream"
      />

      {
        stage === 'setup-video' &&
        <SetupLivestream
          livestream={livestream}
          initStream={initStream}
          updateStage={initStartStream}
          sessionConnected={sessionConnected}
          videoControl={videoControl}
          toggleAudio={toggleAudio}
          toggleVideo={toggleVideo}
        />
      }

      {
        stage === 'start-video' &&
        <StartLivestream
          livestream={livestream}
          startStream={startStream}
          updateStage={endLivestream}
          sessionConnected={sessionConnected}
          videoControl={videoControl}
          toggleAudio={toggleAudio}
          toggleVideo={toggleVideo}
          messages={messages}
          members={members}
        />
      }

      {stage === 'end-video' && <EndLivestream livestream={livestream}/>}
    </div>
  )
}

const mapStateToProps = ({user, livestream}) => ({
  currentAdmin: user.currentAdmin,
  session: livestream.session,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading)),
  startSession: (session) => dispatch(startSession(session)),
  destroySession: () => dispatch(destroySession()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewLivestream));