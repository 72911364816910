export const fetchStatData = (authorization, url, callback) => {
    fetch(url, {
        headers: {
            Authorization: `Bearer ${authorization}`,
        },
    }).then(jsonRes => {
        jsonRes.json().then(res => {
            if (res.status) callback(parseInt(res.data.count))
        })
    })
}