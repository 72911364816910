import "./custom-input.styles.scss";
import { ReactComponent as UploadImg } from "../../assets/icons/upload-icon.svg";
import CustomSelect from "../general/custom-select/custom-select";
import React from "react";

export const CustomInput = ({ handleChange, label, id, ...props }) => {
  return (
    <div className="form-group" id={`${id}-cover`}>
      <label htmlFor={id}>{label}</label>
      <input className="dashboard-input" onChange={handleChange} id={id} {...props} maxLength={100} />
    </div>
  );
};

export const CustomTextarea = ({ handleChange, label, id, ...props }) => {
  return (
    <div className="form-group" id={`${id}-cover`}>
      <label htmlFor={id}>{label}</label>
      <textarea
        className="dashboard-input"
        onChange={handleChange}
        id={id}
        {...props}
      />
    </div>
  );
};

export const CustomSelectInput = ({ label, id, dropDown, ...props }) => {
    return (
        <div className="form-group" id={`${id}-cover`}>
            <label htmlFor={id}>{label}</label>
            <CustomSelect className="dropdown-input" dropDown={dropDown} {...props} />
        </div>
    );
};

export const CustomFileInput = ({ handleChange, label, id, classes, src = '',  ...props }) => {
    return (
        <div className={`form-group ${classes}`} id={`${id}-cover`}>
            <label htmlFor={id}>{label}</label>
            <label className="file-input-label-placeholder">
                <img className="file-input-img-plc" src={src ? src : '/assets/icon/image-placeholder.svg'} alt="placeholder"/>
                <input id={id} type="file" onChange={handleChange} {...props} />
            </label>
        </div>
    );
};
