import types from './types'
import defaultState from './state'

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case types.SET_LOADING:
      return {...state, loading: action.payload,}

    default:
      return state
  }
}