import React, {useEffect, useState} from "react";
import LiveIC from "../../../assets/icons/live-ic.svg";
import ToggleVideo from "../../../assets/icons/toggle-video-ic.svg";
import ToggleAudio from "../../../assets/icons/toggle-audio.svg";
import EndIC from "../../../assets/icons/end-ic.svg";
import MembersIC from "../../../assets/icons/members-ic.svg";
import MembersActiveIC from "../../../assets/icons/members-active-ic.svg";
import LivestreamDetails from "../../../assets/icons/livestream-details-ic.svg";
import LivestreamDetailsActive from "../../../assets/icons/livestream-detail-active-ic.svg";
import LiveStreamChat from "../../../assets/icons/livestream-chat.svg";
import LiveStreamChatActive from "../../../assets/icons/livestream-chat-active-ic.svg";
import MembersSideSection from "./side-section/members";
import DetailsSideSection from "./side-section/details";
import ChatSideSection from "./side-section/chats";
import OT from '@opentok/client'

const StartLivestream = (
  {livestream, startStream, updateStage, sessionConnected, videoControl, toggleAudio, toggleVideo, messages, members}
) => {
  const [activeSideSection, setActiveSideSection] = useState('')

  const toggleSideSection = (section) => {
    if (activeSideSection === section) setActiveSideSection('')
    else setActiveSideSection(section)
  }

  useEffect(() => {
    if (sessionConnected) {
      const startPublisher = OT.initPublisher('start-video', {
        insertMode: 'append',
        width: '100%',
        height: '100%'
      }, (e) => console.error(e ? e : ''));

      startPublisher.publishAudio(videoControl.audio)
      startPublisher.publishVideo(videoControl.video)

      startStream(startPublisher)
    }
  }, [sessionConnected])

  return (
    <div className="setup-livestream-page">
      <div className="row">
        <div className="col video-player-cover">
          <div className="timeline row">
            <div className="tm-icon col-auto">
              <img src={LiveIC} alt="live-ic"/>
            </div>
            <p className="col-auto">Live</p>
            <p className="col-auto timestamp text-muted">00:00:00</p>
          </div>
          <div className="video-player" id="start-video">
          </div>
        </div>
        {
          activeSideSection !== '' &&
          <div className="col-md-4 d-flex flex-column">
            <div className="side-section flex-grow-1">
              {activeSideSection === 'details' && <DetailsSideSection livestream={livestream}/>}
              {
                activeSideSection === 'members' &&
                <MembersSideSection members={members}/>
              }
              {activeSideSection === 'chat' && <ChatSideSection messages={messages}/>}
            </div>
          </div>
        }
      </div>

      <div className="row">
        <div className="col">
          <div className="video-player-controls row align-items-center">
            <h4 className="col-auto livestream-title">{livestream.title}</h4>
            <div className="col-auto row mx-auto">
              <div className="col-auto">
                <div className="control-cover">
                  <div className="control" onClick={toggleVideo}>
                    <img src={ToggleVideo} alt="control"/>
                    {
                      !videoControl.video &&
                      <div className="disabled"/>
                    }
                  </div>
                  <p>Video</p>
                </div>
              </div>
              <div className="col-auto">
                <div className="control-cover">
                  <div className="control" onClick={toggleAudio}>
                    <img src={ToggleAudio} alt="control"/>
                    {
                      !videoControl.audio &&
                      <div className="disabled"/>
                    }
                  </div>
                  <p>Audio</p>
                </div>
              </div>
              <div className="col-auto">
                <div className="control-cover">
                  <div className="control" onClick={updateStage}>
                    <img src={EndIC} alt="control"/>
                  </div>
                  <p>End</p>
                </div>
              </div>
            </div>
            <div className="col-auto row">
              <div className="col-auto">
                <div className="control-cover">
                  <div className="side-control" onClick={() => toggleSideSection('details')}>
                    <img
                      src={
                        activeSideSection === 'details' ?
                          LivestreamDetailsActive :
                          LivestreamDetails
                      }
                      alt="control"
                    />
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <div className="control-cover">
                  <div className="side-control" onClick={() => toggleSideSection('members')}>
                    <img
                      src={
                        activeSideSection === 'members' ?
                          MembersActiveIC :
                          MembersIC
                      }
                      alt="control"
                    />
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <div className="control-cover">
                  <div className="side-control" onClick={() => toggleSideSection('chat')}>
                    <img
                      src={
                        activeSideSection === 'chat' ?
                          LiveStreamChatActive :
                          LiveStreamChat
                      }
                      alt="control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StartLivestream