import "./transactions.scss"

import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import SectionNav from "../../../components/landing-section-nav/landing-section-nav";
import Api from "../../../utils/action";
import Paginate from "../../../components/paginate/paginate";
import {formatCurrency, formatFullDate, perPage} from "../../../utils/global";
import {Modal} from "bootstrap";
import {setLoading} from "../../../redux/dashboard/actions";
import TransactionsFilter from "../../../components/transactions/transactions-filter";

const Transactions = ({currentAdmin, setLoading}) => {
    const [pagination, setPagination] = useState({
        total: 1,
        totalItems: 1,
        page: 0,
        perPage,
    });
    const [transactions, setTransactions] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [transactionsModal, setTransactionsModal] = useState(null);
    const [transactionsFilters, setTransactionsFilters] = useState({
        reference: '', type: '', status: '', email: '', amount: '', from: '', to: ''
    })

    const fetchTransactions = async (reset = false) => {
        setLoading(true)

        const page = reset ? 0 : pagination.page
        const res = await Api.transactions.fetchTransactions(currentAdmin.token, page, transactionsFilters)
        if (res.status) {

            setTransactions(res.data.result.map(tr => {
                tr.amount = formatCurrency(tr.amount)
                tr.user.full_name = `${tr.user.last_name} ${tr.user.first_name}`
                return tr
            }))

            setPagination(pagination => ({
                ...pagination,
                total: res.data.page_info.total_pages,
                totalItems: res.data.page_info.total,
                page,
            }))
        }

        setLoading(false)
    }

    const setPage = (page) => {
        setPagination(pagination => ({ ...pagination, page: page }))
    }

    const showTransaction = (transaction) => {
        setSelectedTransaction(transaction)
        transactionsModal.show()
    }

    useEffect(() => {
        fetchTransactions().then().catch(e => console.error(e))
    }, [currentAdmin.token, pagination.page])

    useEffect(() => {
        const transactionsModalElem = document.querySelector('.show-transactions')
        if (transactionsModalElem) setTransactionsModal(new Modal(transactionsModalElem))
    }, [])

    return (
        <div className="transactions-page">
            <SectionNav header="Transactions" />

            <div className="col-md-4">
                <TransactionsFilter
                  filters={transactionsFilters}
                  setFilters={setTransactionsFilters}
                  filterRes={fetchTransactions}
                />
            </div>

            <div className="dashboard-card">
                <div className="dashboard-table-cover">
                    <table>
                        <thead>
                        <tr className="headers">
                            <th>Transaction Reference</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th className="text-right">Amount (N)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            transactions &&
                            transactions.map(transaction => (
                                <tr key={transaction.id} onClick={() => showTransaction(transaction)} className="transaction">
                                    <td className='no-wrap'>{transaction.reference}</td>
                                    <td className='no-wrap type-col'>{transaction.type}</td>
                                    <td className='no-wrap status-col'>
                                        {transaction.status === 'success' && <span className={`badge badge-success`}>{transaction.status}</span>}
                                        {transaction.status === 'pending' && <span className={`badge badge-warning`}>{transaction.status}</span>}
                                        {transaction.status === 'failed' && <span className={`badge badge-danger`}>{transaction.status}</span>}
                                    </td>
                                    <td className='no-wrap'>{formatFullDate(new Date(transaction.transaction_date), true)}</td>
                                    <td className='no-wrap text-right'>{transaction.amount}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>

            <Paginate
              currentPage={pagination.page}
              totalPages={pagination.total}
              setPage={setPage}
            />

            <div className="modal show-transactions">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <p className="modal-title">TRANSACTION SUMMARY</p>
                            <div className="close-btn" onClick={() => transactionsModal.hide()}>
                                <span>X</span>
                            </div>
                        </div>
                        <div className="modal-body">
                            {
                                selectedTransaction &&
                                <div className="transaction-details row">
                                    <div className="detail col-sm-6">
                                        <p className="title">Transaction Reference</p>
                                        <p className="value">{selectedTransaction.reference}</p>
                                    </div>
                                    <div className="detail col-sm-6">
                                        <p className="title">Amount</p>
                                        <p className="value">{selectedTransaction.amount}</p>
                                    </div>
                                    <div className="detail col-sm-6">
                                        <p className="title">Status</p>
                                        <p className="value status-col">
                                            {selectedTransaction.status === 'success' && <span className={`badge badge-success`}>{selectedTransaction.status}</span>}
                                            {selectedTransaction.status === 'pending' && <span className={`badge badge-warning`}>{selectedTransaction.status}</span>}
                                            {selectedTransaction.status === 'failed' && <span className={`badge badge-danger`}>{selectedTransaction.status}</span>}
                                        </p>
                                    </div>
                                    <div className="detail col-sm-6">
                                        <p className="title">Date</p>
                                        <p className="value">{formatFullDate(new Date(selectedTransaction.transaction_date))}</p>
                                    </div>
                                    <div className="detail col-sm-6">
                                        <p className="title">Type</p>
                                        <p className="value type-col">{selectedTransaction.type}</p>
                                    </div>
                                    <div className="detail col-sm-6">
                                        <p className="title">Channel</p>
                                        <p className="value">{selectedTransaction.channel}</p>
                                    </div>
                                    <div className="detail col-sm-6">
                                        <p className="title">Full Name</p>
                                        <p className="value">{selectedTransaction.user.full_name}</p>
                                    </div>
                                    <div className="detail col-sm-6">
                                        <p className="title">Email</p>
                                        <p className="value">{selectedTransaction.user.email}</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ user }) => ({
    currentAdmin: user.currentAdmin,
});

const mapDispatchToProps = (dispatch) => ({
    setLoading: (loading) => dispatch(setLoading(loading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);