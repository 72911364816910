import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import FilterIC from "../../assets/icons/filter-ic.svg";
import {CustomInput, CustomSelectInput} from "../form-elements/custom-input";
import React, {useState} from "react";
import {generateItem} from "../../utils/helper";
import produce from "immer";

const UsersFilter = ({ auth, filters, setFilters, filterRes })  => {

  const [statusDropdown, setStatusDropdown] = useState({
    id: 'status-filter-select',
    title: 'All',
    items: [
      generateItem('All', '', '', '', ''),
      generateItem('Activated', 'active', '', '', ''),
      generateItem('Deactivated', 'inactive', '', '', ''),
    ]
  });

  const [subStatusDropdown, subSetStatusDropdown] = useState({
    id: 'sub-status-filter-select',
    title: 'All',
    items: [
      generateItem('All', '', '', '', ''),
      generateItem('Subscribed', true, '', '', ''),
      generateItem('Not Subscribed', false, '', '', ''),
    ]
  });
  
  const getStatusFilterName = (status) => {
    if (status === '') return 'All'
    else if (status === 'active') return 'Activated'
    else return 'Deactivated'
  }

  const getSubStatusFilterName = (subscribed) => {
    if (subscribed === '') return 'All'
    else if (subscribed) return 'Subscribed'
    else return 'Not Subscribed'
  }

  const updateFilters = (value, key) => {
    setFilters(filters => produce(filters, data => { data[key] = value }))
  }

  const reset = () => {
    setFilters({ first_name: '', last_name: '', phone_number: '', email: '', status: '', subscribed: '' })
  }

  return (
    <div className="dropdown filter" id="users-dropdown">
      <div className="dropdown-toggle filter-toggle" id="users-dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
        <div>FILTER BY</div>
        <div className="dropdown-arrow-down">
          <img src={FilterIC} alt="filter-ic" />
        </div>
      </div>
      <div className="dropdown-menu filter-menu" aria-labelledby="#users-dropdown-toggle">
        <div className="">
          <CustomInput
            id="first-name"
            name="first-name"
            type="text"
            label="First Name"
            value={filters.first_name}
            handleChange={(e) => updateFilters(e.target.value, 'first_name')}
          />
        </div>
        <div className="">
          <CustomInput
            id="last-name"
            name="last-name"
            type="text"
            label="Last Name"
            value={filters.last_name}
            handleChange={(e) => updateFilters(e.target.value, 'last_name')}
          />
        </div>
        <div className="">
          <CustomInput
            id="phone-number"
            name="phone-name"
            type="tel"
            label="Phone Number"
            value={filters.phone_number}
            handleChange={(e) => updateFilters(e.target.value, 'phone_number')}
          />
        </div>
        <div className="">
          <CustomInput
            id="email"
            name="email"
            type="email"
            label="Email"
            value={filters.email}
            handleChange={(e) => updateFilters(e.target.value, 'email')}
          />
        </div>
        <div className="">
          <CustomSelectInput
            id="status"
            dropDown={statusDropdown}
            label="Status"
            selected={{ value: filters.status, text: getStatusFilterName(filters.status) }}
            onChange={(item) => updateFilters(item.value, 'status')}
          />
        </div>
        <div className="">
          <CustomSelectInput
            id="subscribed"
            dropDown={subStatusDropdown}
            label="Subscribed"
            selected={{ value: filters.subscribed, text: getSubStatusFilterName(filters.subscribed) }}
            onChange={(item) => updateFilters(item.value, 'subscribed')}
          />
        </div>
        <div className="row action-buttons">
          <div className="col-md-6">
            <button type="button" className="btn-primary reset-btn" onClick={reset}>
              RESET
            </button>
          </div>
          <div className="col-md-6">
            <button type="button" className="btn-primary" onClick={() => filterRes(true)}>
              FILTER
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ user }) => ({
  auth: user.currentAdmin.token,
});

export default withRouter(connect(mapStateToProps)(UsersFilter))