import {CustomInput, CustomSelectInput} from "../form-elements/custom-input";
import React, {useEffect, useState} from "react";
import {generateItem} from "../../utils/helper";
import {Spinner, toaster} from "evergreen-ui";
import Api from "../../utils/action";

const TeammateModal = (
    {
        saveTeammateCb,
        closeModal,
        roles,
        auth,
        teammate,
        updateTeammate,
    }
) => {
    const [teammateRoleSelect, setTeammateRoleSelect] = useState(null)
    const [actionRunning, setActionRunning] = React.useState(false);
    const [emailAttributes, setEmailAttributes] = React.useState({});

    useEffect(() => {
        if (teammate.id) setEmailAttributes({ readOnly: 'readyOnly' })
        else setEmailAttributes({})
    }, [teammate])

    useEffect(() => {
        const rolesSelect = {
            id: 'teammate-roles-select',
            title: 'Select Role',
            items: [],
        }

        roles.forEach(role => {
            rolesSelect.items.push(generateItem(
                role.name,
                role.id,
                '',
                '',
                '',
            ))
        })

        setTeammateRoleSelect(rolesSelect)
    }, [roles])

    const updateRole = (role) => updateTeammate({ ...teammate, role_id: role.value })
    const updateEmail = (e) => updateTeammate({ ...teammate, email: e.target.value })
    const getRoleName = (id) => {
        const role = roles.find(role => role.id === id)
        return role ? role.name : 'Select Role'
    }

    const saveTeammate = () => {
        if (actionRunning) return
        else setActionRunning(true)
        let error = false

        if (teammate.email === '') {
            toaster.danger("Please enter the email");
            error = true
        }

        if (teammate.role_id <= 0) {
            toaster.danger("Please select a role");
            error = true
        }

        if (!error) {
            if (teammate.id) {
                const data = { role_id: teammate.role_id }

                Api.teammates.updateTeammate(auth, teammate.id, data, async (res) => {
                    if (res.status) {
                        toaster.success('Teammate Updated')
                        saveTeammateCb(res)
                        setActionRunning(false)
                    }
                })
            } else {
                const data = {
                    email: teammate.email,
                    role_id: teammate.role_id,
                }

                Api.teammates.addTeammate(auth, data, async (res) => {
                    if (res.status) {
                        toaster.success('Invitation has been sent.')
                        saveTeammateCb(res)
                        setActionRunning(false)
                    }
                })
            }
        }
    }

    return (
        <div className="modal settings-modal teammate-modal">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title">
                            {
                                teammate.id ?
                                    'UPDATE TEAMMATE' :
                                    'ADD A TEAMMATE'
                            }
                        </p>
                        <div className="close-btn" onClick={closeModal}>
                            <span>X</span>
                        </div>
                    </div>
                    <div className="modal-body">
                        {
                            <CustomInput
                                id="email"
                                label="Email Address"
                                value={teammate.email}
                                handleChange={updateEmail}
                                { ...emailAttributes }
                            />
                        }
                        {
                            teammateRoleSelect &&
                            <CustomSelectInput
                                id="role"
                                label="Role"
                                dropDown={teammateRoleSelect}
                                selected={{ value: teammate.role_id, text: getRoleName(teammate.role_id) }}
                                onChange={updateRole}
                            />
                        }
                    </div>
                    <div className="modal-footer">
                        {
                            !actionRunning ? (
                                <button className="btn-primary submit-btn" onClick={saveTeammate}>
                                    {
                                        teammate.id ?
                                        'UPDATE' :
                                        'ADD'
                                    }
                                </button>
                            ) : (<Spinner size={16} className="spinner"/>)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeammateModal