import ApiCalls from "./api";

const Activities = {
  fetchClasses: async (auth, page, filters) => await ApiCalls.getForQuery(auth, 'admin/classes', page, filters),
  getAudit: async (auth, page, filters) => {
    filters = Object.entries(filters)
    filters.push(['type', 'admin'])
    return await ApiCalls.getForQuery(auth, 'admin/activities', page, filters)
  },
  getActivities: async (auth, page, filters) => {
    filters = Object.entries(filters)
    filters.push(['type', 'user'])
    return await ApiCalls.getForQuery(auth, 'admin/activities', page, filters)
  },
  getUserActivities: async (auth, email) => await ApiCalls.getForQuery(auth, 'admin/activities', 0, [['email', email]])
}
export default Activities