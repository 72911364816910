import "./save-plan.styles.scss";
import { TopSectionNav } from "../../../../components/top-section-nav/top-section-nav";
import {connect} from "react-redux";
import React, {useState} from "react";
import {toaster} from "evergreen-ui";
import {useHistory} from "react-router-dom";
import SavePlanForm from "../../../../components/subscription/save-plan-form";

const AddPlan = ({ currentAdmin }) => {
  const history = useHistory()
  const [plan, setPlan] = useState({
    name: '',
    profiles: 0,
    devices: 0,
    monthly_amount: 0,
    yearly_amount: 0,
    description: '',
  })

  const savedPlan = (message) => {
    toaster.success("Plan Created");
    history.push('/dashboard/subscriptions')
  }

  return (

      <div className="save-plan">
        <TopSectionNav
            path="/dashboard/subscriptions"
            header="Subscription"
            back="Subscriptions"
        />
        <SavePlanForm
          plan={plan}
          setPlan={setPlan}
          savedPlan={savedPlan}
        />
      </div>
  );
};

const mapStateToProps = ({ user }) => ({
  currentAdmin: user.currentAdmin,
})

export default connect(mapStateToProps)(AddPlan);
