import { useState, useEffect } from "react";
const useFetch = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [length, updateLength] = useState(0);

  useEffect(() => {
    fetch("https://jsonplaceholder.typicode.com/posts")
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        updateLength(data.length);
      })
      .catch((err) => {
        setError(err);
      });

    // eslint-disable-next-line
  }, []);

  // console.log(data);

  return { data, length, error };
};

export default useFetch;
