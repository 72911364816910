import {baseUrl, perPage} from "../global";
import {toaster} from "evergreen-ui";

const getForQuery = async (auth, url, page, filters = []) => {
    url  = `${baseUrl}/${url}?page=${page}&size=${perPage}`;

    if (!Array.isArray(filters)) filters = Object.entries(filters)

    filters.forEach(filter => {
        if (filter[1] !== null && filter[1] !== '') url += `&${filter[0]}=${filter[1]}`
    })

    const request = await fetch(url, {
        method: "GET",
        headers: {
            "content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
        },
    });

    validateRes(request)
    return await request.json();
}

const getById = async (auth, url, id) => {
    url  = `${baseUrl}/${url}/${id}`;

    try {
        const request = await fetch(url, {
            method: "GET",
            headers: {
                "content-Type": "application/json",
                Authorization: `Bearer ${auth}`,
            },
        });

        validateRes(request)
        return await request.json();
    } catch (e) {
        console.error(e.message)
    }
}

const getFetchList = (auth, url, filters = [], cb) => {
    url  = `${baseUrl}/${url}`;
    if (filters.length > 0) url += '?'
    filters.forEach(filter => {
        url += `&${filter[0]}=${filter[1]}`
    })

    try {
        const request = fetch(url, {
            method: "GET",
            headers: {
                "content-Type": "application/json",
                Authorization: `Bearer ${auth}`,
            },
        });

        request.then(response => {
            validateRes(response)
            response.json().then(res => {
                cb(res)
            })
        })
    } catch (e) {
        console.error(e.message)
    }
}

const getFetchListAsync = async (auth, url, filters = []) => {
    url  = `${baseUrl}/${url}`;
    if (filters.length > 0) url += '?'
    filters.forEach(filter => {
        url += `&${filter[0]}=${filter[1]}`
    })

    try {
        const request = await fetch(url, {
            method: "GET",
            headers: {
                "content-Type": "application/json",
                Authorization: `Bearer ${auth}`,
            },
        });

        validateRes(request)
        return await request.json()
    } catch (e) {
        console.error(e.message)
    }
}

const postForRes = (auth, url, data, cb) => {
    url  = `${baseUrl}/${url}`;

    try {
        const request = fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "content-Type": "application/json",
                Authorization: `Bearer ${auth}`,
            },
        });

        request.then(response => {
            validateRes(response)
            response.json().then(res => {
                cb(res)
            })
        })
    } catch (e) {
        console.error(e.message)
    }
}

const postForResAsync = async (auth, url, data) => {
    url  = `${baseUrl}/${url}`;

    try {
        const request = await fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "content-Type": "application/json",
                Authorization: `Bearer ${auth}`,
            },
        });

        validateRes(request)
        return await request.json()
    } catch (e) {
        console.error(e.message)
    }
}

const patchForRes = (auth, url, data, cb) => {
    url  = `${baseUrl}/${url}`;

    try {
        const request = fetch(url, {
            method: "PATCH",
            body: JSON.stringify(data),
            headers: {
                "content-Type": "application/json",
                Authorization: `Bearer ${auth}`,
            },
        });

        request.then(response => {
            validateRes(response)
            response.json().then(res => {
                cb(res)
            })
        })
    } catch (e) {
        console.error(e.message)
    }
}

const patchForResAsync = async (auth, url, data) => {
    url  = `${baseUrl}/${url}`;

    try {
        const request = await fetch(url, {
            method: "PATCH",
            body: JSON.stringify(data),
            headers: {
                "content-Type": "application/json",
                Authorization: `Bearer ${auth}`,
            },
        });

        validateRes(request)
        return await request.json()
    } catch (e) {
        console.error(e.message)
    }
}

const putForRes = async (auth, url, data) => {
    url  = `${baseUrl}/${url}`;

    try {
        const request = await fetch(url, {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
                "content-Type": "application/json",
                Authorization: `Bearer ${auth}`,
            },
        });

        validateRes(request)
        return await request.json()
    } catch (e) {
        console.error(e.message)
    }
}

const uploadFile = async (auth, type, data) => {
    const url = `${baseUrl}/admin/uploads?type=${type}`;

    try {
        const request = await fetch(url, {
            method: "POST",
            body: data,
            headers: {
                Authorization: `Bearer ${auth}`,
            },
        });

        validateRes(request)
        return await request.json();
    } catch (e) {
        console.error(e.message)
    }
}

const deleteReq = (auth, url, id, cb) => {
    url  = `${baseUrl}/${url}/${id}`;

    try {
        const request = fetch(url, {
            method: "DELETE",
            headers: {
                "content-Type": "application/json",
                Authorization: `Bearer ${auth}`,
            },
        });

        request.then(response => {
            validateRes(response)
            response.json().then(res => {
                cb(res)
            })
        })
    } catch (e) {
        console.error(e.message)
    }
}

const deleteReqAsync = async (auth, url, id) => {
    url  = `${baseUrl}/${url}/${id}`;

    try {
        const request = await fetch(url, {
            method: "DELETE",
            headers: {
                "content-Type": "application/json",
                Authorization: `Bearer ${auth}`,
            },
        });

        validateRes(request)
        return await request.json();
    } catch (e) {
        console.error(e.message)
    }
}

const validateRes = (res, cb = null) => {

    if (res.status >= 500) {
        toaster.danger("An unknown error occurred");
    } else if (res.status === 403) {
        toaster.danger("Permission Denied");
    } else if (res.status === 401) {
        toaster.danger("Unauthorised");
        localStorage.clear();
        window.location.href = '/'
    } else  {
        if (cb) cb(res)
    }
}

const ApiCalls = {
    getForQuery,
    getById,
    getFetchList,
    getFetchListAsync,
    postForRes,
    postForResAsync,
    patchForRes,
    patchForResAsync,
    putForRes,
    uploadFile,
    deleteReq,
    deleteReqAsync
}
export default ApiCalls