import "./profile.scss"

import {connect} from "react-redux";
import SectionNav from "../../../components/landing-section-nav/landing-section-nav";
import SearchInput from "../../../components/general/search-input/search-input";
import {useEffect, useState} from "react";
import {toaster} from "evergreen-ui";
import Api from "../../../utils/action";
import {withRouter} from "react-router-dom";

const Profile = ({currentAdmin}) => {
    const [selectedTab, setSelectedTab] = useState("profile-section");

    const updatePassword = () => {
        const oldPasswordElem = document.querySelector('#old-password')
        const newPasswordElem = document.querySelector('#new-password')

        const oldPassword = oldPasswordElem.value
        const newPassword = newPasswordElem.value

        if (oldPassword === '' || newPassword.value === '') {
            toaster.danger('Old and new password must be filled')
        } else {
            Api.account.updatePassword(currentAdmin.token, {
                old_password: oldPassword,
                new_password: newPassword,
            })
        }
    }

    return (
        <div className="profile-page-cover">
            <SectionNav header="Transactions" />

            <div className="dashboard-card">
                <div className="dashboard-tab-view">
                    <div className="dashboard-tab-heading">
                        <div className="dashboard-tab-menu">
                            <span
                                className={`tab-menu-item${selectedTab === 'profile-section' ? ' active' : ''}`}
                                onClick={() => setSelectedTab('profile-section')}
                            >
                                PROFILE
                            </span>
                            <span
                                className={`tab-menu-item${selectedTab === 'security' ? ' active' : ''}`}
                                onClick={() => setSelectedTab('security')}
                            >
                                SECURITY
                            </span>
                        </div>
                    </div>
                    <div className="dashboard-tab-body">
                        {
                            selectedTab === 'profile-section' ?
                            (
                                <div className="profile">
                                    <div className="form-group profile-detail">
                                        <label>First Name</label>
                                        <input type="email" className="dashboard-input" value={currentAdmin.first_name} readOnly />
                                    </div>

                                    <div className="form-group profile-detail">
                                        <label>Last Name</label>
                                        <input type="email" className="dashboard-input" value={currentAdmin.last_name} readOnly />
                                    </div>

                                    <div className="form-group profile-detail">
                                        <label>Email</label>
                                        <input type="email" className="dashboard-input" value={currentAdmin.email} readOnly />
                                    </div>

                                    <div className="form-group profile-detail">
                                        <label>Role</label>
                                        <input type="email" className="dashboard-input" value={currentAdmin.role.name} readOnly />
                                    </div>
                                </div>
                            ):
                            (
                                <div className="security">
                                    <div className="form-group profile-detail">
                                        <label>Old Password</label>
                                        <input type="password" className="dashboard-input" id="old-password" name="old_password"/>
                                    </div>

                                    <div className="form-group profile-detail">
                                        <label>New Password</label>
                                        <input type="password" className="dashboard-input" id="new-password" name="new_password"/>
                                    </div>

                                    <div className="action-btn">
                                        <button className="btn btn-primary" onClick={updatePassword}>SAVE CHANGES</button>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ user }) => ({
    currentAdmin: user.currentAdmin,
});

export default withRouter(connect(mapStateToProps)(Profile));