import {useEffect, useState} from "react";
import CaretDown from "../../../assets/icons/arrow-down-grey.svg";
import {useHistory} from "react-router-dom";

const CustomSelect = ({
    dropDown,
    className,
    onChange = null,
    selected = null,
    readOnly = false
}) => {

    const history = useHistory()
    const [selectedItem, setSelectedItem] = useState({ text: dropDown.title, value: '' })

    const toggleActive = (item) => {
        setSelectedItem(item)
        if (onChange) onChange(item)
    }

    const handleItemAction = (item) => {
        if (item.hasAction) item.action()
        if (item.url) history.push(item.url)
    }

    return (
        <div className={`dropdown ${className}`} id={dropDown.id}>
            <div className={`dropdown-toggle ${readOnly && 'disabled'}`} id={`${dropDown.id}-toggle`} data-bs-toggle="dropdown" aria-expanded="false">
                <span>{ selected ? selected.text : selectedItem.text }</span>
                <span className="dropdown-arrow-down">
                    <img src={CaretDown} alt="caretDown" />
                </span>
            </div>
            <ul className="dropdown-menu" aria-labelledby={`#${dropDown.id}-toggle`}>
                {dropDown.items
                .filter(
                    item => selected ?
                        item.value !== selected.value :
                        item.value !== selectedItem.value
                )
                .map(item => (
                    <li
                        id={item.id}
                        className={ `dropdown-item ${item.classes}` }
                        key={item.value}
                        onClick={() => {
                            handleItemAction(item)
                            toggleActive(item)
                        }}
                    >
                        { item.text }
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default CustomSelect