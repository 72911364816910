import React, { useState } from "react";
import "./form-password-input.styles.scss";
import { ReactComponent as Eye } from "../../assets/images/EyeVector.svg";

export const FormPasswordInput = ({
  handleChange,
  name,
  label,
  errorText,
  ...otherProps
}) => {
  const [visible, setVisibility] = useState(false);
  const toggleVisibility = () => {
    setVisibility((prev) => !prev);
  };
  return (
    <div className="password-container">
      <label htmlFor="new-password">{label}</label>
      <div className="password-div">
        <input
          type={visible === false ? "password" : "text"}
          name={name}
          onChange={handleChange}
          {...otherProps}
        />
        <Eye className="eye" onClick={toggleVisibility} />
      </div>
      <span className="error_text">{errorText}</span>
    </div>
  );
};

export default FormPasswordInput;
