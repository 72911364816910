import React, { useEffect, useState } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import "./dashboard.styles.scss";
import Menu from "../../components/dashboard-menu/dashboard-menu.component";
import Activity from "../dashboard-sections/activities/activities.component";
import Home from "../dashboard-sections/dashboard-home/home.component";
import Settings from "../dashboard-sections/dashboard-setting/setting";
import Classes from "../dashboard-sections/dashboard-classes/classes";
import AuditTrail from "../dashboard-sections/audit-trail/audit-trail";
import Users from "../dashboard-sections/users/users";
import UserDetail from "../dashboard-sections/users/user-detail/user-detail";
import LiveStream from "../dashboard-sections/live-stream/live-stream";
import LivestreamDetail from "../dashboard-sections/live-stream/livestream-detail/livestream-detail";
import AddLivestream from "../dashboard-sections/live-stream/add-livestream/add-livestream";
import HomeDetailPage from "../dashboard-sections/dashboard-home/dashboard-home-detail/dashboard-home-detail";
import Subscriptions from "../dashboard-sections/subscriptions/subscriptions";
import PlanDetails from "../dashboard-sections/subscriptions/plan-details/plan-details";
import AddPlan from "../dashboard-sections/subscriptions/save-plan/add-plan";
import ToggleButton from "../../components/toggle-button/toggle-button";
import Transactions from "../dashboard-sections/transactions/transactions";
import Profile from "../dashboard-sections/profile/profile";
import ClassDetail from "../dashboard-sections/dashboard-classes/class-detail/class-detail";
import AddLesson from "../dashboard-sections/dashboard-classes/class-detail/subject-detail/add-lesson/add-lesson";
import SubjectDetail from "../dashboard-sections/dashboard-classes/class-detail/subject-detail/subject-detail";
import SaveTests from "../dashboard-sections/dashboard-classes/class-detail/subject-detail/save-test/save-tests";
import ViewLivestream from "../dashboard-sections/live-stream/initialize-livestream/view-livestream";
import EditLesson from "../dashboard-sections/dashboard-classes/class-detail/subject-detail/edit-lesson/edit-lesson";
import { connect } from "react-redux";
import EditLivestream from "../dashboard-sections/live-stream/add-livestream/edit-livestream";
import EditPlan from "../dashboard-sections/subscriptions/save-plan/edit-plan";
import ApiCalls from "../../utils/action/api";
import { toaster } from "evergreen-ui";
import Api from "../../utils/action";
import {
  removeFromFileQueue,
  setFileQueue,
  updateLastUploadedFile,
} from "../../redux/file-upload-queue/actions";

const DashboardPage = ({
  auth,
  loading,
  fileUploadQueue,
  removeFromFileQueue,
  updateLastUploadedFile,
  lastUploadedFile,
  setFileQueue,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);

  const upload = async (fileUpload) => {
    setFileUploading(true);
    const { file, lessonId, title, type, number } = fileUpload;

    if (!number) {
      const formData = new FormData();
      formData.append("file", file, file.name);

      const res = await ApiCalls.uploadFile(auth, type, formData);
      if (res) {
        if (res.status) {
          await addLessonFile(lessonId, res.data.url, title, type, null);
        } else toaster.danger(res.message);
      }
    } else {
      await addLessonFile(lessonId, null, title, type, number);
    }

    setFileUploading(false);
  };

  const addLessonFile = async (id, url, title, type, number) => {
    let file;
    if (type === "videos") file = { title, url, number };
    else file = { name: title, url };
    const res = await Api.lessons.update(auth, id, { [type]: [file] });
    if (res) {
      if (res.status) toaster.success(`${title} uploaded`);
      else toaster.danger(res.message);
    }
  };

  useEffect(() => {
    if (!fileUploading && fileUploadQueue) {
      if (fileUploadQueue.length > 0) {
        const fileUpload = fileUploadQueue[0];
        if (fileUpload) {
          removeFromFileQueue();
          updateLastUploadedFile(fileUpload);
          upload(fileUpload).then();
        }
      }
    }
  }, [fileUploading, fileUploadQueue]);

  return (
    <div className="dashboard">
      <div
        className={
          isMobile ? "active dashboard-leftcolumn" : "dashboard-leftcolumn"
        }
      >
        <Menu isMobile={isMobile} />
      </div>
      <div className="dashboard-rightcolumn">
        <div
          className="toggler"
          onClick={() => {
            setIsMobile((prev) => !prev);
          }}
        >
          <ToggleButton />
        </div>

        <Switch>
          <Route exact path="/dashboard" component={Home} />
          <Route
            exact
            path="/dashboard/detail/:id"
            component={HomeDetailPage}
          />
          <Route exact path="/dashboard/users" component={Users} />
          <Route path="/dashboard/users/:id" component={UserDetail} />
          <Route path="/dashboard/activities" component={Activity} />
          <Route path="/dashboard/audit-trail" component={AuditTrail} />
          <Route exact path="/dashboard/settings" component={Settings} />
          <Route exact path="/dashboard/classes" component={Classes} />
          <Route exact path="/dashboard/classes/:id" component={ClassDetail} />
          <Route
            exact
            path="/dashboard/classes/:classId/:subjectId"
            component={SubjectDetail}
          />
          <Route
            exact
            path="/dashboard/classes/:classId/:subjectId/add-lesson"
            component={AddLesson}
          />
          <Route
            exact
            path="/dashboard/classes/:classId/:subjectId/lesson/:lessonId/:action"
            component={EditLesson}
          />
          <Route
            exact
            path="/dashboard/classes/:classId/:subjectId/add-test"
            component={SaveTests}
          />
          <Route
            exact
            path="/dashboard/classes/:classId/:subjectId/test/:id/:action"
            component={SaveTests}
          />
          <Route exact path="/dashboard/livestream" component={LiveStream} />
          <Route
            exact
            path="/dashboard/livestream/:id"
            component={LivestreamDetail}
          />
          <Route
            exact
            path="/dashboard/livestream/:id/edit"
            component={EditLivestream}
          />
          <Route
            exact
            path="/dashboard/livestream/:id/view"
            component={ViewLivestream}
          />
          <Route path="/dashboard/add-livestream" component={AddLivestream} />
          <Route
            exact
            path="/dashboard/subscriptions"
            component={Subscriptions}
          />
          <Route
            exact
            path="/dashboard/subscriptions/new"
            component={AddPlan}
          />
          <Route
            exact
            path="/dashboard/subscriptions/:id"
            component={PlanDetails}
          />
          <Route
            exact
            path="/dashboard/subscriptions/:id/edit"
            component={EditPlan}
          />
          <Route path="/dashboard/transactions" component={Transactions} />
          <Route path="/dashboard/profile" component={Profile} />
        </Switch>

        {loading && (
          <div className="loader-cover">
            <div className="loader" />
          </div>
        )}

        {lastUploadedFile && fileUploading && (
          <div className="upload-cover">
            <p>
              Uploading {lastUploadedFile.title}, {fileUploadQueue.length} left
              in queue
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ dashboard, user, fileUploadQueue }) => ({
  loading: dashboard.loading,
  fileUploadQueue: fileUploadQueue.fileUploadQueue,
  lastUploadedFile: fileUploadQueue.lastUploadedFile,
  auth: user.currentAdmin.token,
});

const mapDispatchToProps = (dispatch) => ({
  setFileQueue: (cachedFileQueue) => dispatch(setFileQueue(cachedFileQueue)),
  removeFromFileQueue: () => dispatch(removeFromFileQueue()),
  updateLastUploadedFile: (videoUpload) =>
    dispatch(updateLastUploadedFile(videoUpload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardPage)
);
