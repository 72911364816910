import {baseUrl, perPage} from "../global";
import {toaster} from "evergreen-ui";
import ApiCalls from "./api";

const deleteTeammate = (id, auth, cb) => {
    const delStatus = fetch(`${baseUrl}/admin/team/${id}`, {
        method: 'DELETE',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
        }
    })

    delStatus.then(res => {
        res.json().then(res => {
            if (res.status) {
                toaster.success("Teammate deleted.");
            } else toaster.danger(res.message)
            cb()
        })
    }).catch(e => {
        console.error(e)
    })
}

const updateTeammateStatus = (id, status, auth, cb) => {
    const delStatus = fetch(`${baseUrl}/admin/team/${id}?status=${status}`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
        }
    })

    delStatus.then(res => {
        res.json().then(res => {
            if (res.status) {
                toaster.success("Teammate Status Updated.");
            } else toaster.danger(res.message)
            cb()
        })
    }).catch(e => {
        console.error(e)
    })
}

const Teammates = {
    addTeammate: (auth, data, cb) => ApiCalls.postForRes(auth, 'admin/team/invite', data, cb),
    fetchTeammates: async(auth, page, filters) => await ApiCalls.getForQuery(auth, 'admin/team', page, filters),
    updateTeammate: (auth, id, data, cb) => ApiCalls.patchForRes(auth, `admin/team/${id}`, data, cb),
    deleteTeammate,
    updateTeammateStatus
}

export default Teammates