import React, {useState} from "react";
import ClockIc from "../../../assets/icons/clock-ic.svg";
import TutorIc from "../../../assets/icons/tutor-ic.svg";
import {timeTo12HourFormat} from "../../../utils/global";

const EndLivestream = (
    { livestream, }
) => {

    return (
        <div className="end-livestream-page">
            <div className="start-livestream-page init-livestream">
                <h3 className="intro-text text-center">You have ended the <span>LIVE STREAM</span></h3>
                <div className="row details">
                    <div className="col-auto">
                        <div className="detail row mx-1">
                            <div className="col-auto icon">
                                <img src={ClockIc} alt='tutor-ic' />
                            </div>
                            <p className="col">{ timeTo12HourFormat(livestream.event_start_time) } - { timeTo12HourFormat(livestream.event_close_time) }</p>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="detail row mx-1">
                            <div className="col-auto icon">
                                <img src={TutorIc} alt='tutor-ic' />
                            </div>
                            <p className="col">{ livestream.tutor_name }</p>
                        </div>
                    </div>
                </div>

                <div className="action-btn row mt-5 mb-2">
                    <a href={`/dashboard/add-livestream`}>
                        <button className="btn btn-primary col-auto mx-auto">
                            CREATE NEW LIVESTREAM
                        </button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default EndLivestream