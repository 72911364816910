import './dashboard-plan-card.scss'

const DashboardPlanCard = ({
    title,
    value,
    className,
}) => {

    return (
        <div className={`dashboard-card dashboard-plan-card ${className}`}>
            <div className="side-box">
                <p className="plan-name">{ title }</p>
                <p className="plan-value">{ value }</p>
            </div>
        </div>
    )
}

DashboardPlanCard.defaultProps = {
    title: 'BASIC PLAN',
    value: '5,301',
}

export default DashboardPlanCard