import './dashboard-stat-card.scss'
import CustomSelect from "../custom-select/custom-select";
import CustomDropdown from "../custom-select/custom-dropdown";

const DashboardStatCard = ({
    title,
    icon,
    value,
    dropDown,
    hasSelect,
    hasDropdown,
    className,
    clickable,
    onClick,
}) => {
    let handleClick = () => {
        if (clickable) {
            onClick();
        }
    }


    return (
        <div className={`dashboard-card dashboard-stats-card ${className}`} onClick={handleClick}>
            <div className="top">
                <span className="box-icon">
                    { icon.startsWith('http') ? <img src={icon} alt="UserIcon" /> : <img src={`/assets/icon/${icon}`} alt="UserIcon" /> }
                </span>
                { hasSelect ? <CustomSelect dropDown={dropDown} /> : '' }
                { hasDropdown ? <CustomDropdown dropDown={dropDown} /> : '' }
            </div>
            <div className="bottom">
                <p className="title">{ title }</p>
                <p className="value">{ value }</p>
            </div>
        </div>
    )
}

DashboardStatCard.defaultProps = {
    hasDropDown: false,
    hasSelect: false,
    hasDropdown: false,
    icon: 'user-ic-orange.svg',
    title: 'Number of Users',
    value: 0,
    clickable: false,
    onClick: null,
}

export default DashboardStatCard