import {timeTo12HourFormat} from "../../../../utils/global";

const DetailsSideSection = (
  {livestream}
) => {

  return (
    <div className='details-side-section'>
      <h4 className="side-section-header">Livestream details</h4>
      <div className="my-5">
        <p className="side-section-title">About This Session</p>
        <p>{ livestream.description }</p>
      </div>
      <div className="mt-5">
        <p className="side-section-title">Class</p>
        <p>{ livestream.class.name }</p>
      </div>
      <div className="mt-5">
        <p className="side-section-title">Tutor</p>
        <p>{ livestream.tutor_name }</p>
      </div>
      <div className="mt-5">
        <p className="side-section-title">Duration</p>
        <p>{ timeTo12HourFormat(livestream.event_start_time) } - { timeTo12HourFormat(livestream.event_close_time) }</p>
      </div>
    </div>
  )
}

export default DetailsSideSection