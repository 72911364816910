import React, {useEffect, useState} from "react";
import {ReactComponent as Back} from "../../assets/images/back.svg";
import {ReactComponent as Forward} from "../../assets/images/forward.svg";
import './paginate.scss'

const Paginate = ({
                    currentPage,
                    totalPages,
                    setPage,
                  }) => {
  const [pages, setPages] = useState([...Array(totalPages).keys()].map(p => p + 1))
  const [leftPages, setLeftPages] = useState([])
  const [rightPages, setRightPages] = useState([])
  const [startIndex, setStartIndex] = useState(0)
  const [slots, setSlots] = useState(5)
  const limit = 5
  const leftMax = 3

  useEffect(() => {
    setPages([...Array(totalPages).keys()].map(p => p + 1))
  }, [totalPages])

  useEffect(() => {
    let tempLimit = limit
    let tempStartIndex = currentPage - leftMax
    if (tempStartIndex >= 0) {
      tempLimit -= leftMax
    } else {
      tempLimit -= (leftMax + tempStartIndex)
      tempStartIndex = 0
    }

    if ((currentPage + tempLimit) >= totalPages) {
      tempStartIndex -= (currentPage + tempLimit) - totalPages
      if (tempStartIndex <= 0) {
        tempStartIndex = 0
      }
    }

    setSlots(tempLimit)
    setStartIndex(tempStartIndex)

  }, [currentPage, totalPages])

  useEffect(() => {
    const leftPages = pages.slice(startIndex, currentPage)
    const rightPages = pages.slice(currentPage, currentPage + slots)

    setLeftPages(leftPages)
    setRightPages(rightPages)
  }, [currentPage, pages, slots, startIndex])

  const setCurrentPage = (pageNo) => {
    if (pageNo >= 0 && pageNo < totalPages) {
      setPage(pageNo)
    }
  }

  return (
    <div className="paginate-container">
      <p className="page-count">
        PAGES {currentPage + 1} OF {totalPages}
      </p>
      <div className="paginated">
        <div
          className="prev"
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          <Back/>
        </div>
        {leftPages
          .map((num) => (
            <div
              key={num}
              className="page-num"
              onClick={() => setCurrentPage(num - 1)}
            >
              <span>{num}</span>
            </div>
          ))}

        {rightPages
          .map((num) => (
            <div
              key={num}
              className={
                currentPage + 1 === num ? "page-num active" : "page-num"
              }
              onClick={() => setCurrentPage(num - 1)}
            >
              <span>{num}</span>
            </div>
          ))}

        <div
          className="next"
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          <Forward/>
        </div>
      </div>
    </div>
  );
};

export default Paginate;
