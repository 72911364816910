import {useState} from "react";
import CaretDown from "../../../assets/icons/arrow-down-grey.svg";
import {useHistory} from "react-router-dom";

const CustomDropdown = ({
    dropDown
}) => {
    const history = useHistory();

    const handleItemAction = (item) => {
        if (item.hasAction) item.action()
        if (item.url) history.push(item.url)
    }

    return (
        <div className="dropdown" id={dropDown.id}>
            <div className="dropdown-toggle" id={`${dropDown.id}-toggle`} data-bs-toggle="dropdown" aria-expanded="false">
                <span>{ dropDown.title }</span>
                <span className="dropdown-arrow-down">
                    <img src={CaretDown} alt="caretDown" />
                </span>
            </div>
            <ul className="dropdown-menu" aria-labelledby={`#${dropDown.id}-toggle`}>
                {dropDown.items
                .map(item => (
                    <li
                        id={item.id}
                        className={ `dropdown-item ${item.classes}` }
                        key={item.value}
                        onClick={() => handleItemAction(item)}
                    >
                        { item.text }
                    </li>
                ))
                }
            </ul>
        </div>
    )
}

export default CustomDropdown