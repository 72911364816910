import ApiCalls from "./api";

const Classes = {
    addClass: (auth, data, cb) => ApiCalls.postForRes(auth, 'admin/classes', data, cb),
    updateClass: (auth, id, data, cb) => ApiCalls.patchForRes(auth, `admin/classes/${id}`, data, cb),
    fetchClasses: async (auth, page, filters) => await ApiCalls.getForQuery(auth, 'admin/classes', page, filters),
    fetchSubjects: async (auth, classId) => {
        const filters = []
        if (classId) filters.push(['class_id', classId])

        return await ApiCalls.getFetchListAsync(auth, 'admin/subjects', filters)
    },
    fetchSubjectById: async (auth, subjectId) => await ApiCalls.getById(auth, 'admin/subjects', subjectId),
    fetchClass: async (auth, id) => await ApiCalls.getById(auth, 'admin/classes', id),
    fetchClassesList: async (auth) => await ApiCalls.getFetchListAsync(auth, 'admin/classes', []),
    delete: async (auth, id) => await ApiCalls.deleteReqAsync(auth, `admin/classes`, id),
}

export default Classes