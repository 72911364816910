import types from './types'
import defaultState from './state'

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case types.CREATE_SESSION:
      return { ...state, session: action.payload, }

    case types.DESTROY_SESSION:
      return { ...state, session: null, }

    default: return state
  }
}
