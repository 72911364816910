import ApiCalls from "./api";

const Tests = {
    fetch: async (auth, page, classId = null, subjectId = null, term = null) => {
        const filters = []
        if (classId) filters.push(['class_id', classId])
        if (subjectId) filters.push(['subject_id', subjectId])
        if (term) filters.push(['term', term])

        return await ApiCalls.getForQuery(auth, 'admin/assignments', page, filters)
    },
    fetchById: async (auth, id) => await ApiCalls.getById(auth, 'admin/assignments', id),
    create: (auth, data, cb) => ApiCalls.postForRes(auth, 'admin/assignments', data, cb),
    update: (auth, id, data, cb) => ApiCalls.patchForRes(auth, `admin/assignments/${id}`, data, cb),
    delete: (auth, id, cb) => ApiCalls.deleteReq(auth, `admin/assignments`, id, cb),
}

export default Tests