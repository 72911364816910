import React, {useEffect, useState} from "react";
import SectionNav from "../../../components/landing-section-nav/landing-section-nav";
import { connect } from "react-redux";
import Paginate from "../../../components/paginate/paginate";
import {formatActivity, perPage} from "../../../utils/global";
import DashboardTable from "../../../components/general/dashboard-table/dashboard-table";
import Api from "../../../utils/action";
import {setLoading} from "../../../redux/dashboard/actions";
import ActivitiesFilter from "../../../components/activities/activities-filter";

const AuditTrail = ({ currentAdmin, setLoading }) => {
  const [audit, setAudit] = useState([])
  const [auditFilters, setAuditFilters] = useState({ first_name: '', last_name: '', email: '', from: '', to: ''})

  const [auditTable, setAuditTable] = useState({
    cols: [
      {title: 'Date', classes: 'no-wrap', key: 'date'},
      {title: 'User', classes: 'no-wrap', key: 'fullName'},
      {title: 'Email', classes: 'no-wrap', key: 'owner_email'},
      {title: 'Description', classes: '', key: 'description'},
    ],
    data: []
  });

  const [pagination, setPagination] = useState({
    total: 1,
    totalItems: 1,
    page: 0,
    perPage,
  })

  const fetchAudit = async (reset = false) => {
    setLoading(true)

    const page = reset ? 0 : pagination.page
    const res = await Api.activities.getAudit(currentAdmin.token, page, auditFilters)
    if (res.status) {
      setAudit(res.data.result)
      setPagination(pagination => ({
        ...pagination,
        total: res.data.page_info.total_pages,
        totalItems: res.data.page_info.total,
        page,
      }))
    }

    setLoading(false)
  }

  const setPage = (page) => {
    setPagination(pagination => ({ ...pagination, page: page }))
  }

  useEffect(() => {
    fetchAudit().then(() => {}).catch(e => console.error(e))
  }, [currentAdmin.token, pagination.page])

  useEffect(() => {
    setAuditTable(table => ({
      ...table,
      data: formatActivity(audit)
    }))
  }, [audit])

  return (
      <div className="activity">
        <SectionNav header="Audit Trail" />

        <div className="col-md-4">
          <ActivitiesFilter
            filters={auditFilters}
            setFilters={setAuditFilters}
            filterRes={fetchAudit}
          />
        </div>

        <div className="dashboard-card">
          <DashboardTable columns={auditTable.cols} data={auditTable.data} />
        </div>

        <Paginate
          currentPage={pagination.page}
          totalPages={pagination.total}
          setPage={setPage}
        />
      </div>
  )
}

const mapStateToProps = ({ user }) => ({
  currentAdmin: user.currentAdmin,
})

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditTrail);
