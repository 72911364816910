import React from "react";

const Button = ({ children }) => {
  return (
      <div className="btn-cover">
          <button className="btn-primary">
              {children}
          </button>
      </div>
  );
};

export default Button;
