import React from "react";

const LiveStreamMember = (
  {member}
) => {
  return (
    <div className="member">
      <div className="d-flex align-items-center">
        <div className="profile-image me-3">
          <img src={`/assets/icon/user-ic-orange.svg`} alt='userIcon' />
        </div>
        <p className="name flex-grow-1">{member.name}</p>
      </div>
      <hr className='divider' />
    </div>
  )
}

export default LiveStreamMember