import React from "react";
import {timeTo12HourFormat} from "../../../../utils/global";

const LiveStreamChat = (
  {chat}
) => {
  return (
    <div className="chat">
      {
        chat &&
        <div className="d-flex">
          <div className={`content flex-grow-1 ${chat.mine ? 'ms-5' : 'me-5'}`}>
            <p className="name">{chat.fullName}</p>
            <div className="message">{chat.message}</div>
            <p className="time flex-grow-1">{timeTo12HourFormat(chat.timestamp)}</p>
          </div>
        </div>
      }
    </div>
  )
}

export default LiveStreamChat