import React from "react";
import "./form-input.styles.scss";

const FormInput = ({ handleChange, name, type="text", label, errorText, ...otherProps }) => {
  return (
    <div className="form-input-container">
      <label htmlFor={name}>{label}</label>
      <input name={name} type={type} onChange={handleChange} {...otherProps} />
      <span className="error_text">{errorText}</span>
    </div>
  );
};

export default FormInput;
