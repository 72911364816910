import "./save-lesson-form.scss";
import {
  CustomFileInput,
  CustomInput,
  CustomSelectInput,
  CustomTextarea,
} from "../../form-elements/custom-input";
import { termDropdown } from "../../../utils/helper";
import { Spinner, TagInput, toaster } from "evergreen-ui";
import React, { useEffect, useState } from "react";
import ApiCalls from "../../../utils/action/api";
import Api from "../../../utils/action";
import { useHistory } from "react-router-dom";
import { setLoading } from "../../../redux/dashboard/actions";
import { connect } from "react-redux";
import LessonFiles from "../lesson-videos/lesson-files";
import { addToFileQueue } from "../../../redux/file-upload-queue/actions";

const SaveLessonForm = ({
  auth,
  lesson,
  updateLesson,
  classId,
  savedLesson,
  loading,
  setLoading,
  addToFileQueue,
  readOnly = false,
}) => {
  const history = useHistory();
  const [newVideos, setNewVideos] = useState([]);
  const [newDocuments, setNewDocuments] = useState([]);
  const [classDetails, setClassDetails] = useState(null);
  const [terms, setTerms] = useState({
    first: "First Term",
    second: "Second Term",
    third: "Third Term",
  });
  const [errors, setErrors] = useState({
    topic: null,
    term: null,
    description: null,
    downloadable: null,
    previewDuration: null,
    tags: null,
    videos: null,
    documents: null,
  });

  useEffect(() => {
    async function fetchClass() {
      const res = await Api.classes.fetchClass(auth, classId);
      if (res.status) setClassDetails(res.data);
    }

    fetchClass()
      .then(() => {})
      .catch((e) => console.log(e));
  }, [auth, classId]);

  const setTopic = (e) => updateLesson("topic", e.target.value);
  const setTerm = (term) => updateLesson("term", term.value);
  const setDescription = (e) => updateLesson("description", e.target.value);
  const setTutorName = (e) => updateLesson("tutor_name", e.target.value);
  const setTutorBio = (e) => updateLesson("tutor_bio", e.target.value);
  const setDownloadable = (e) => updateLesson("downloadable", e.target.checked);
  const setPreviewDuration = (e) =>
    updateLesson("preview_duration", e.target.value);
  const setTags = (tags) => updateLesson("tags", tags);

  const validateForm = () => {};

  const previewImage = (e, key) => {
    let reader = new FileReader();
    const file = e && e.target.files.length > 0 ? e.target.files[0] : null;

    if (file) {
      reader.onload = function () {
        updateLesson(key, reader.result);
        updateLesson(`${key}_file`, file);
      };

      reader.readAsDataURL(file);
    }
  };

  const uploadImages = async (image, url = "") => {
    if (image) {
      const imageData = new FormData();
      imageData.append("file", image, image.name);
      const imageRes = await ApiCalls.uploadFile(auth, "images", imageData);
      if (imageRes.status) return imageRes.data.url;
    } else {
      if (url.startsWith("http")) return url;
      else throw new Error("Error uploading image");
    }
  };

  const submitForm = async (draft = false) => {
    setLoading(true);

    const newVideos = lesson.videos.filter((video) => !video.id);
    const oldVideos = lesson.videos.filter((video) => video.id);

    const newDocuments = lesson.documents.filter((doc) => !doc.id);
    const oldDocuments = lesson.documents.filter((doc) => doc.id);

    const data = {
      class_id: lesson.class_id,
      topic: lesson.topic,
      description: lesson.description,
      title: lesson.title,
      downloadable: lesson.downloadable,
      tutor_name: lesson.tutor_name,
      tutor_bio: lesson.tutor_bio,
      thumbnail: lesson.thumbnail,
      term: lesson.term,
      subject: lesson.subject,
      tags: lesson.tags,
      preview_duration: lesson.preview_duration,
      draft,
    };

    if (data.thumbnail && data.thumbnail !== "") {
      data.thumbnail = await uploadImages(
        lesson.thumbnail_file,
        data.thumbnail
      );
    }

    data.videos = oldVideos.map((video) => {
      return {
        id: video.id,
        url: video.url,
        title: video.title,
        number: video.number,
        delete: video.delete,
      };
    });

    data.documents = oldDocuments.map((doc) => {
      return { id: doc.id, url: doc.url, name: doc.title, delete: doc.delete };
    });

    try {
      const res = lesson.id
        ? await Api.lessons.update(auth, lesson.id, data)
        : await Api.lessons.create(auth, data);

      if (res.status) {
        for (const doc of newDocuments)
          addToFileQueue({ ...doc, lessonId: res.data.id });
        for (const vid of newVideos)
          addToFileQueue({ ...vid, lessonId: res.data.id });
        savedLesson(res);
      } else toaster.danger(res.message);
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  return (
    lesson && (
      <form className="add-lesson-form save-lesson-form row">
        <div className="col-md-6 mt-3">
          <CustomFileInput
            id="thumbnail"
            label="Upload Thumbnail"
            src={lesson.thumbnail}
            accept="image/png,image/jpg,image/jpeg,.svg"
            handleChange={(e) => previewImage(e, "thumbnail")}
          />
        </div>
        <div className="col-md-6 mt-3"></div>

        <div className="col-md-6 mt-3">
          <CustomInput
            id="class"
            name="class"
            type="text"
            label="Class"
            readOnly
            value={classDetails ? classDetails.name : ""}
          />
        </div>

        <div className="col-md-6 mt-3">
          <CustomInput
            id="subject"
            name="subject"
            type="text"
            label="Subject"
            readOnly
            value={lesson.subject}
          />
        </div>

        <div className="col-md-6 mt-3">
          <CustomInput
            id="topic"
            name="topic"
            type="text"
            label="Topic"
            value={lesson.topic}
            readOnly={readOnly}
            handleChange={setTopic}
          />
        </div>

        <div className="col-md-6 mt-3">
          <CustomSelectInput
            id="term"
            name="term"
            dropDown={termDropdown()}
            label="Term"
            selected={{ text: terms[lesson.term], value: lesson.term }}
            onChange={setTerm}
            readOnly={readOnly}
          />
        </div>

        <div className="col-md-6 mt-3">
          <CustomTextarea
            id="description"
            name="description"
            type="text"
            placeholder="Description"
            label="Description"
            value={lesson.description}
            handleChange={setDescription}
            readOnly={readOnly}
          />
        </div>

        <div className="col-md-6 mt-3">
          <CustomInput
            id="tutor-name"
            name="tutor-name"
            type="text"
            label="Tutor Name"
            value={lesson.tutor_name}
            handleChange={setTutorName}
            readOnly={readOnly}
          />
        </div>

        <div className="col-md-6 mt-3">
          <CustomInput
            id="preview-duration"
            name="preview-duration"
            type="number"
            label="Preview Duration"
            value={lesson.preview_duration}
            handleChange={setPreviewDuration}
            readOnly={readOnly}
          />
        </div>

        <div className="col-md-6 mt-3">
          <CustomTextarea
            id="tutor-bio"
            name="tutor-bio"
            type="text"
            placeholder=""
            label="Tutor Bio"
            value={lesson.tutor_bio}
            handleChange={setTutorBio}
            readOnly={readOnly}
          />
        </div>

        <div className="col-md-6 mt-3">
          <div id="tag-cover" className="form-group">
            <label>Tags/Keywords</label>
            <TagInput
              tagProps={{
                color: "neutral",
                height: "30px",
                display: "flex",
                justifyContent: "space-between",
                fontSize: "17px",
                fontFamily: "Avenir",
                paddingX: "10px",
              }}
              marginTop="1rem"
              backgroundColor="#fff"
              height={40}
              width="100%"
              borderRadius={7}
              border="none"
              outline="none"
              inputProps={{
                placeholder: "Add tag...",
                color: "#ff6c00",
                fontSize: "17px",
              }}
              readOnly={readOnly}
              values={lesson.tags}
              onChange={(values) => {
                setTags(values);
              }}
            />
          </div>
        </div>

        <div className="col-md-6 mt-3">
          <div id="download-toggle-cover" className="form-group">
            <label>Download on Device</label>
            <label className="switch">
              <input
                type="checkbox"
                id="download-toggle"
                value={lesson.downloadable}
                onChange={setDownloadable}
                disabled={readOnly}
              />
              <span className="slider round" />
            </label>
          </div>
        </div>

        <hr />

        <div className="col-md-6 mt-3">
          <div className="form-group">
            <label>Videos</label>
          </div>
        </div>

        <div className="col-md-6 mt-3">
          <div className="form-group">
            <label>Documents</label>
          </div>
        </div>

        <div className="col-md-6 mt-3">
          <div className="form-group">
            <LessonFiles
              readOnly={readOnly}
              lessonId={lesson.id}
              files={lesson.videos}
              updateFiles={(videos) => updateLesson("videos", videos)}
              type="videos"
            />
          </div>
        </div>

        <div className="col-md-6 mt-3">
          <div className="form-group">
            <LessonFiles
              readOnly={readOnly}
              lessonId={lesson.id}
              files={lesson.documents}
              updateFiles={(documents) => updateLesson("documents", documents)}
              type="documents"
              accepts="application/pdf"
            />
          </div>
        </div>

        <hr />

        {!readOnly && (
          <div id="publish-cover" className="publish mt-4">
            {!loading ? (
              <button
                type="button"
                className="btn-primary m-2"
                onClick={() => submitForm(true)}
              >
                SAVE AS DRAFT
              </button>
            ) : (
              <Spinner size={16} className="spinner" />
            )}
            {!loading ? (
              <button
                type="button"
                className="btn-primary"
                onClick={() => submitForm(false)}
              >
                PUBLISH
              </button>
            ) : (
              <Spinner size={16} className="spinner" />
            )}
          </div>
        )}
      </form>
    )
  );
};

const mapStateToProps = ({ dashboard }) => ({
  loading: dashboard.loading,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading)),
  addToFileQueue: (file) => dispatch(addToFileQueue(file)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveLessonForm);
