import "./top-section-nav.styles.scss";
import { Link } from "react-router-dom";
import ArrowLeft from "../../assets/images/Arrow1.png";
import NotificationBar from "../notification-bar/notification-bar.component";

export const TopSectionNav = ({ path, header, back }) => {
  return (
    <div className="top_section_nav">
      <span className="top_nav_header">{header}</span>
      <NotificationBar className="notification" />
      <Link to={path}>
        <div className="go_back">
          <img src={ArrowLeft} alt="ArrowLeft" />
          <span>{back}</span>
        </div>
      </Link>
    </div>
  );
};
