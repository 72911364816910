import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import FilterIC from "../../assets/icons/filter-ic.svg";
import {CustomInput} from "../form-elements/custom-input";
import React from "react";
import produce from "immer";

const ActivitiesFilter = ({ auth, filters, setFilters, filterRes })  => {

  const updateFilters = (value, key) => {
    setFilters(filters => produce(filters, data => { data[key] = value }))
  }

  const reset = () => {
    setFilters({ first_name: '', last_name: '', email: '', from: '', to: ''})
  }

  return (
    <div className="dropdown filter" id="activities-dropdown">
      <div className="dropdown-toggle filter-toggle" id="activities-dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
        <div>FILTER BY</div>
        <div className="dropdown-arrow-down">
          <img src={FilterIC} alt="filter-ic" />
        </div>
      </div>
      <div className="dropdown-menu filter-menu" aria-labelledby="#activities-dropdown-toggle">
        <div className="">
          <CustomInput
            id="first-name"
            type="text"
            label="First Name"
            value={filters.first_name}
            handleChange={(e) => updateFilters(e.target.value, 'first_name')}
          />
        </div>
        <div className="">
          <CustomInput
            id="last-name"
            type="text"
            label="Last Name"
            value={filters.last_name}
            handleChange={(e) => updateFilters(e.target.value, 'last_name')}
          />
        </div>
        <div className="">
          <CustomInput
            id="email"
            type="email"
            label="Email"
            value={filters.email}
            handleChange={(e) => updateFilters(e.target.value, 'email')}
          />
        </div>
        <div className="">
          <CustomInput
            id="from"
            type="date"
            label="From"
            value={filters.from}
            handleChange={(e) => updateFilters(e.target.value, 'from')}
          />
        </div>
        <div className="">
          <CustomInput
            id="to"
            type="date"
            label="to"
            value={filters.to}
            handleChange={(e) => updateFilters(e.target.value, 'to')}
          />
        </div>
        <div className="row action-buttons">
          <div className="col-md-6">
            <button type="button" className="btn-primary reset-btn" onClick={reset}>
              RESET
            </button>
          </div>
          <div className="col-md-6">
            <button type="button" className="btn-primary" onClick={() => filterRes(true)}>
              FILTER
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ user }) => ({
  auth: user.currentAdmin.token,
});

export default withRouter(connect(mapStateToProps)(ActivitiesFilter))