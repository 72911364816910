import './dashboard-table.scss'
import React from "react";
import TableMenu from "../custom-select/table-menu";
import Empty from "../empty/empty";

const DashboardTable = ({
    tableId,
    columns,
    data,
    hasMenu,
}) => {

    return (
        <div className={`dashboard-table-cover`}>
            <table>
                <thead>
                <tr className="headers">
                    { columns.map(col => <th className={col.classes} key={`${tableId}-${col.key}`}>{col.title}</th>) }
                </tr>
                </thead>
                <tbody>
                {data.map(item => (
                    <tr className="headers" key={`${tableId}-${item.id}`}>
                        { columns.map(col => {
                            return (typeof item[col.key] === 'object' && item[col.key] !== null) ? (
                                <td className={`${col.classes} ${item[col.key].classes}`} key={`item-${tableId}-${item.id}-${col.key}`}>
                                    {item[col.key].value}
                                </td>
                            ):(
                                <td className={col.classes} key={`item-${tableId}-${item.id}-${col.key}`}>
                                    {item[col.key]}
                                </td>
                            )
                        }) }
                        {
                            hasMenu &&
                            <td className="table-menu-cover">
                                <TableMenu dropDown={item.dropdown} />
                            </td>
                        }
                    </tr>
                ))}
                </tbody>
            </table>
            {
                data.length <= 0 &&
                <Empty message="Oops, Nothing was found" />
            }
        </div>
    )
}

DashboardTable.defaultProps = {
    tableId: 'item',
}

export default DashboardTable