import React, {useEffect, useState} from "react";
import LiveStreamChat from "./chat";
import SendIc from "../../../../assets/icons/send-ic.svg"
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {setLoading} from "../../../../redux/dashboard/actions";
import produce from "immer";

const ChatSideSection = (
  {currentAdmin, livestream, session, messages, setLoading}
) => {
  const [newChat, setNewChat] = useState({message: '', timestamp: '', fullName: ''})

  useEffect(() => {
    if (currentAdmin) {
      setNewChat(chat => produce(
        chat,
        data => {
          data.fullName = `${currentAdmin.first_name} ${currentAdmin.last_name}`
        })
      )
    }
  }, [currentAdmin])

  const setMessage = (e) => {
    setNewChat(data => produce(data, newData => {
      newData.message = e.target.value
    }))
  }

  const sendMessage = (e) => {
    e.preventDefault()
    setLoading(true)

    session.signal({
      type: 'msg',
      data: JSON.stringify({
        ...newChat,
        timestamp: new Date(),
      }),
    }, function (error) {
      if (error) console.error('Error sending signal:', error.name, error.message);
      else setNewChat({...newChat, message: '', timestamp: ''});
      setLoading(false)
    });
  }

  return (
    <div className='chats-side-section d-flex flex-column h-100'>
      <h4 className="side-section-header flex-shrink-0">Comments</h4>
      <div className="messages my-2 flex-grow-1">
        {
          messages.map((chat, id) => (
            <LiveStreamChat chat={chat} key={`chat-${id}`}/>
          ))
        }
      </div>
      <form onSubmit={sendMessage} className="mt-auto flex-shrink-0">
        <div className="dashboard-input custom-input-group send-comment-input">
          <input className="send-input" placeholder="Type a comment" value={newChat.message} onChange={setMessage}/>
          <div className="input-group-append send-ic" onClick={sendMessage}>
            <img src={SendIc} alt="send-ic"/>
          </div>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = ({user, livestream}) => ({
  currentAdmin: user.currentAdmin,
  session: livestream.session,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatSideSection))