import React from "react";
import LiveStreamMember from "./member";

const MembersSideSection = (
  {members}
) => {

  return (
    <div className='members-side-section'>
      <h4 className="side-section-header">Your Pupils</h4>
      <div className="my-5">
        {
          members.map(member => (
            <LiveStreamMember key={member.id} member={member}/>
          ))
        }
      </div>
    </div>
  )
}

export default MembersSideSection