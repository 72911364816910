import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";

const TableMenu = ({
    dropDown,
}) => {
    const history = useHistory();

    const handleItemAction = (item) => {
        if (item.action) item.action()
        else if (item.url) history.push(item.url)
    }

    return (
        <div className="dropdown dropstart" id={dropDown.id}>
            <div className="dropdown-toggle" id={`${dropDown.id}-menu`} data-bs-toggle="dropdown" aria-expanded="false">
                <div className="dots">
                    <div/>
                    <div/>
                    <div/>
                </div>
            </div>
            <ul className="dropdown-menu" aria-labelledby={`#${dropDown.id}-menu`}>
                {dropDown.items
                .map(item => (
                    <li
                        id={item.id}
                        className={ `dropdown-item ${item.classes}` }
                        key={`${item.id}-${item.value}`}
                        onClick={() => handleItemAction(item)}

                    >
                        <span className="view">{ item.text }</span>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default TableMenu