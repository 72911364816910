import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./admin-user/admin.reducers";
import livestreamReducer from "./livestream/reducer";
import dashboardReducer from "./dashboard/reducer";
import fileUploadQueueReducer from "./file-upload-queue/reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const rootReducer = combineReducers({
  user: userReducer,
  livestream: livestreamReducer,
  dashboard: dashboardReducer,
  fileUploadQueue: fileUploadQueueReducer,
});

export default persistReducer(persistConfig, rootReducer);
