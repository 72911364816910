import types from './types'
import defaultState from './state'
import {produce} from "immer";

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case types.SET_FILE_QUEUE:
      return { ...state, fileUploadQueue: action.payload }
    case types.ADD_TO_FILE_QUEUE:
      return {
        ...state,
        fileUploadQueue: produce(state.fileUploadQueue, data => {
          data.push(action.payload)
        })
      }
    case types.REMOVE_FROM_FILE_QUEUE:
      return {
        ...state,
        fileUploadQueue: produce(state.fileUploadQueue, data => {
          data.shift()
        })
      }
    case types.UPDATE_LAST_UPLOADED_FILE:
      return {...state, lastUploadedFile: action.payload}

    default:
      return state
  }
}