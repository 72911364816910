import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Validate } from "../../utils/auth/validate";
import { setCurrentAdmin } from "../../redux/admin-user/admin.actions";
import { toaster, Spinner } from "evergreen-ui";

import "./login-page.styles.scss";
import logo from "../../assets/images/dyeka-logo-4a.png";

import { LoginLayout } from "../../components/Login-layout/login-layout.component";
import Button from "../../components/custom-button/custom-button.component";
import FormInput from "../../components/form-input/form-input";
import FormPasswordInput from "../../components/form-password-input/form-password-input";
import {baseUrl} from "../../utils/global";

const LoginPage = ({ setCurrentAdmin }) => {
  const initialValues = {
    email: "",
    password: "",
  };
  const [loginCredentials, setLoginCredentials] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { email, password } = loginCredentials;

  const handleChange = (event) => {
    const { name, value } = event.target;

    setLoginCredentials({ ...loginCredentials, [name]: value });
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    setFormErrors(Validate(loginCredentials));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      submitForm();
    }

    // eslint-disable-next-line
  }, [formErrors]);

  const submitForm = () => {
    fetch(`${baseUrl}/admin/auth/login`, {
      method: "POST",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify(loginCredentials),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);\
        setLoading(false);
        if (data.message === "Successful") {
          // console.log(data.data);
          setCurrentAdmin(data.data);
          localStorage.setItem("user", data.data.token);
          toaster.success("Sign in successful.");
        } else {
          toaster.danger(`${data.message}`);
          setFormErrors({
            email: "Please ensure that email is correct",
            password: "Please ensure that password is correct",
          });
        }
      });
  };

  // console.log(formErrors);

  return (
    <div className="auth-cover login">
      <div className="auth-background">
        <LoginLayout heading="Admin Dashboard" name="You've got access!" />
      </div>
      <div className="auth-form-cover">
        <div className="auth-form">
          <form onSubmit={handleSubmit}>
            <h3 className="auth-form-heading">
              <span className="admin">Admin Login to</span>
              <span>
              <img src={logo} alt="dyeka admin logo" />
            </span>
            </h3>
            <p className="auth-form-intro">
            </p>
            <FormInput
                name="email"
                onChange={handleChange}
                value={email}
                label="Email Address"
                type="email"
                errorText={formErrors.email ? `${formErrors.email}` : null}
                className={formErrors.email && "input-error"}
            />
            <FormPasswordInput
                name="password"
                onChange={handleChange}
                value={password}
                label="Password"
                errorText={formErrors.password ? `${formErrors.password}` : null}
                className={formErrors.password && "input-error"}
            />

            <p className="forgot-password">
              <Link to="/reset-password">Forgot password</Link>
            </p>

            <Button type="submit">
              {isLoading && <Spinner size={16} className="spinner" />}
              <span>LOGIN</span>
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentAdmin: (admin) => dispatch(setCurrentAdmin(admin)),
});

export default connect(null, mapDispatchToProps)(LoginPage);
