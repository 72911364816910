import './empty.scss'
import EmptyImage from '../../../assets/icons/empty.svg'

const Empty = ({ message }) => {
  return (
    <div className="empty-page">
      <div className="row justify-content-center align-items-center">
        <div className="col-auto image-cover">
          <img src={EmptyImage} alt="empty" />
        </div>
      </div>
      <p className="message text-center py-3 px-2 px-sm-5">{ message }</p>
    </div>
  )
}

export default Empty