import React from "react";
import './search-input.scss'

const SearchInput = ({
    className = '',
    props
}) => {
    return(
        <div className={`search-input ${className}`}>
            <input type="text" className="dashboard-input" placeholder="Search" />
        </div>
    )
}

export default SearchInput