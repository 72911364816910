import React, {useEffect, useState} from "react";
import "./class-detail.styles.scss";

import {useParams, withRouter} from "react-router-dom";
import { TopSectionNav } from "../../../../components/top-section-nav/top-section-nav";

import DetailIcon from "../../../../assets/icons/class-detail-icon.svg";
import Api from "../../../../utils/action";
import {connect} from "react-redux";
import {generateItem, generateTableCol} from "../../../../utils/helper";
import {formatDate} from "../../../../utils/global";
import DashboardTable from "../../../../components/general/dashboard-table/dashboard-table";
import {setLoading} from "../../../../redux/dashboard/actions";

const ClassDetail = ({ currentAdmin, setLoading }) => {
    const { id } = useParams();
    const [subjectsTable, setSubjectsTable] = useState(null)
    const [classDetails, setClassDetails] = useState(null)
    const [usersTable, setUsersTable] = useState(null);
    const [classOptions, updateClassOptions] = useState("subjects");

    const fetchClass = async () => {
        setLoading(true)

        const res = await Api.classes.fetchClass(currentAdmin.token, id)
        if (res.status) setClassDetails(res.data)

        setLoading(false)
    }

    useEffect(() => {
        fetchClass().then(() => {}).catch(e => console.error(e))
    }, [currentAdmin.token, id])

    useEffect(() => {
        if (classDetails) {
            const usersTable = {
                id: 'class-users-table',
                cols: [
                    generateTableCol('Name', 'full_name', 'no-wrap'),
                    generateTableCol('Interest', 'interest'),
                    generateTableCol('Date of Birth', 'date_of_birth', 'no-wrap'),
                ],
                data: classDetails.profiles ?  classDetails.profiles.map(profile => {
                    profile.full_name = `${profile.last_name} ${profile.first_name}`
                    profile.date_of_birth = formatDate(new Date(profile.dob))
                    profile.interest = profile['interests'].join(", ")

                    return profile
                }) : []
            }

            setUsersTable(usersTable)
        }
        
    }, [classDetails])

    useEffect(() => {
        if (classDetails) {
            const subjectsTable = {
                id: 'class-subjects-table',
                cols: [
                    generateTableCol('Name', 'name', 'no-wrap'),
                    generateTableCol('Description', 'description'),
                ],
                data: classDetails.subjects ?  classDetails.subjects.map(subject => {
                    subject.no_of_videos = subject.no_of_videos ? subject.no_of_videos : 0
                    subject.tags = subject.tags ? subject.tags.join(", ") : ''
                    subject.dropdown = {
                        id: `class-subject-${subject.id}-menu`,
                        title: '',
                        items: [
                            generateItem(
                                'View',
                                'view',
                                '',
                                '',
                                `/dashboard/classes/${id}/${subject.id}`
                            ),
                        ]
                    }

                    return subject
                }) : []
            }

            setSubjectsTable(subjectsTable)
        }

    }, [classDetails, id])

    return (
        <div className="class-detail-page">
            <TopSectionNav
                path="/dashboard/classes"
                header="Classes"
                back="Back to Classes"
            />

            <div className="section-title-cover">
                <div className="section-title-block">
                    <div className="img">
                        <img src={DetailIcon} alt="detailIcon" />
                    </div>
                    <p>{ classDetails && classDetails.name.toUpperCase() }</p>
                </div>
            </div>

            <div className="dashboard-card">
                <div className="dashboard-tab-view">
                    <div className="dashboard-tab-heading">
                        <div className="dashboard-tab-menu">
                            <div
                                onClick={() => updateClassOptions("subjects")}
                                className={`tab-menu-item${classOptions === "subjects" ? " active" : ""}`}
                            >
                                SUBJECTS
                            </div>
                            <div
                                onClick={() => updateClassOptions("users")}
                                className={`tab-menu-item${classOptions === "users" ? " active" : ""}`}
                            >
                                USER
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-tab-body">
                        {
                            classOptions === 'users' &&
                            usersTable &&
                            <DashboardTable
                                columns={usersTable.cols}
                                data={usersTable.data}
                            />
                        }

                        {
                            classOptions === 'subjects' &&
                            subjectsTable &&
                            <DashboardTable
                                columns={subjectsTable.cols}
                                data={subjectsTable.data}
                                hasMenu={true}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ user }) => ({
    currentAdmin: user.currentAdmin,
});

const mapDispatchToProps = (dispatch) => ({
    setLoading: (loading) => dispatch(setLoading(loading)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClassDetail));
