import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
html {
  font-size: 16px;


  @media screen and (min-width: 1400px) {
      // font-size: 22px;
  // display: none;

  }
}

.input-error {
  border: 1px solid red !important;
}
.error {
  font-size: 14px;
  color: red;
}

`;
