import React, {useEffect, useState, useMemo} from "react";
import {connect} from "react-redux";
import {useQuery} from "react-query";
import {Pane, Spinner} from "evergreen-ui";
import "./users.styles.scss";

import SectionNav from "../../../components/landing-section-nav/landing-section-nav";
import Paginate from "../../../components/paginate/paginate";
import DashboardStatContainer from "../../../components/general/dashboard-stat-card/dashboard-stat-card";
import DashboardTable from "../../../components/general/dashboard-table/dashboard-table";

import { fetchStatData } from "../../../utils/api-calls"
import {generateItem, generateItemN} from "../../../utils/helper"
import {baseUrl, perPage} from "../../../utils/global";
import {toggleUserActivation} from "../../../utils/action/toggle-user-status";
import Api from "../../../utils/action";
import UsersFilter from "../../../components/users/users-filter";
import produce from "immer";
import {setLoading} from "../../../redux/dashboard/actions";

const Users = ({ currentAdmin, setLoading }) => {
  const [usersCount, setUsersCount] = useState({ active: 0, inactive: 0, total: 0 })
  const [selectedUserCount, setSelectedUserCount] = useState(0)
  const [profilesCount, setProfilesCount] = useState(0)
  const [usersFilters, setUsersFilters] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    status: '',
    subscribed: '',
  })

  const [usersDropdownFilter, setUsersDropdownFilter] = useState({
    id: 'user-stat-filter',
    title: 'Total',
    items: [],
  });

  const [users, setUsers] = useState([])
  const [usersTable, setUsersTable] = useState(null)
  const usersTableCols = useMemo(() => {
    return [
      {title: 'Name', classes: 'no-wrap', key: 'full_name'},
      {title: 'Email', classes: '', key: 'email'},
      {title: 'Phone Number', classes: 'no-wrap', key: 'phone_number'},
      {title: 'Status', classes: '', key: 'status'},
    ]
  }, [])
  const [pagination, setPagination] = useState({
    total: 1,
    totalItems: 1,
    page: 0,
    perPage,
  });

  const fetchUsers = async (reset = false) => {
    setLoading(true)

    const res = await Api.users.fetchUsers(currentAdmin.token, pagination.page, usersFilters)
    if (res.status) {
      setUsers(res.data.result)
      setPagination(pagination => ({
        ...pagination,
        total: res.data.page_info.total_pages,
        totalItems: res.data.page_info.total,
        page: reset ? 0 : pagination.page
      }))
    }

    setLoading(false)
  }

  const updateUserStatus = (userId, status) => {
    setLoading(true)
    toggleUserActivation(userId, status, currentAdmin.token, fetchUsers)
  }

  const setPage = (page) => {
    setPagination(pagination => ({ ...pagination, page: page }))
  }

  useEffect(() => {
    fetchUsers().then().catch(e => console.error(e))
  }, [currentAdmin.token, pagination.page])

  useEffect(() => {
    Api.dashboard.stats(currentAdmin.token).then(data => {
      setUsersCount({ total: data.totalUsers, inactive: data.inactiveUsers, active: data.activeUsers })
      setSelectedUserCount(data.totalUsers)
      setProfilesCount(data.profiles)
    })
  }, [currentAdmin.token])

  useEffect(() => {
    setUsersDropdownFilter({
      id: 'user-stat-filter',
      title: 'Filter',
      items: [
        generateItem('Total', 'total', '', '', '#', true, () => {
          setSelectedUserCount(usersCount.total)
        }),
        generateItem('Active', 'active', '', 'text-success', '#', true, () => {
          setSelectedUserCount(usersCount.active)
        }),
        generateItem('Inactive', 'inactive', '', 'text-danger', '#', true, () => {
          setSelectedUserCount(usersCount.inactive)
        }),
      ]
    })
  }, [usersCount])

  useEffect(() => {
    const usersTable = {
      cols: usersTableCols,
      data: []
    }

    usersTable.data = users.map(user => {
      user.status = {
        value: user['activated'] ? 'Activated': 'Inactive',
        classes: user['activated'] ? 'text-success': 'text-danger'
      }

      user.full_name = `${user.first_name} ${user.last_name}`;
      user.dropdown = {
        id: `user-dropdown-${user.id}`,
        title: 'Filter',
        items: [
          generateItemN({ text: 'View details', value: 'view_details', url: `/dashboard/users/${user.id}` }),
          generateItemN(
            !user.activated ?
              { text: 'Activate', value: 'active', classes: 'text-success', hasAction: true, action: () => updateUserStatus(user.id, true) } :
              { text: 'Deactivate', value: 'deactivate', classes: 'text-danger', hasAction: true, action: () => updateUserStatus(user.id, false) }
          ),
        ],
      }

      return user
    })

    setUsersTable(usersTable)
  }, [users, usersTableCols])

  return (
    <div className="users classes">
      <SectionNav header="Users" />

      <div className="col-md-4">
        <UsersFilter
          filters={usersFilters}
          setFilters={setUsersFilters}
          filterRes={fetchUsers}
        />
      </div>

      <div className="users_grid">
        <DashboardStatContainer
            className="box1"
            icon="user-ic-orange.svg"
            title="NUMBER OF USERS"
            value={selectedUserCount}
            hasSelect={true}
            dropDown={usersDropdownFilter}
        />

        <DashboardStatContainer
            className="box2"
            icon="profile.svg"
            title="NUMBER OF PROFILES"
            value={profilesCount}
        />

      </div>

      <div className="dashboard-card">
        {
          usersTable &&
          <DashboardTable columns={usersTable.cols} data={usersTable.data} hasMenu={true} />
        }
      </div>

      <Paginate
        currentPage={pagination.page}
        totalPages={pagination.total}
        setPage={setPage}
      />
    </div>
  );
};
const mapStateToProps = ({ user }) => ({
  currentAdmin: user.currentAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
