import React from "react";
import "./dashboard-home-detail.styles.scss";
import useFetch from "../../../../utils/data";
import { TopSectionNav } from "../../../../components/top-section-nav/top-section-nav";

const HomeDetailPage = () => {
  // const { id } = useParams();

  const { data } = useFetch();

  return (
    <div className="class__detail dashboard__detail">
      <TopSectionNav
        path="/dashboard/"
        header="Dashboard"
        back="Back to Dashboard"
      />

      <div className="detail__block">
        <div className="img">
          <svg
            width="74"
            height="67"
            viewBox="0 0 74 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M44.6387 18.8962L27.6898 9.13695C26.8608 8.65825 25.8677 8.65782 25.0353 9.13651C24.2045 9.61477 23.708 10.4721 23.708 11.4295V30.9494C23.708 31.9068 24.2045 32.7636 25.0353 33.2423C25.4515 33.4817 25.907 33.6013 26.3625 33.6013C26.8189 33.6013 27.2744 33.4817 27.6898 33.2428L44.6387 23.4826C45.4695 23.0043 45.966 22.147 45.9669 21.1901C45.9669 20.2327 45.4703 19.3749 44.6387 18.8962Z"
              fill="#FF6C00"
            />
            <path
              d="M68.5855 2.16109H68.0556C67.8159 0.931607 66.7306 0 65.4292 0H55.9979C54.6965 0 53.6113 0.931607 53.3716 2.16109H4.68686C2.10215 2.16109 0 4.25822 0 6.83578V39.327V48.4275C0 51.0055 2.10215 53.1022 4.68686 53.1022H25.546V59.454H21.8505C18.1724 59.454 15.1793 62.4393 15.1793 66.1088C15.1793 66.6014 15.5789 67 16.0728 67H57.1995C57.6934 67 58.0931 66.6014 58.0931 66.1088C58.0931 62.4393 55.1 59.454 51.4219 59.454H47.7263V53.1022H68.5855C71.1702 53.1022 73.2724 51.0055 73.2724 48.4275V39.327V6.83578C73.2724 4.25822 71.1702 2.16109 68.5855 2.16109ZM45.9392 59.454H27.3332V53.1022H45.9392V59.454ZM30.7381 45.7685C30.7381 44.389 31.8629 43.2671 33.246 43.2671H40.0263C41.4094 43.2671 42.5342 44.389 42.5342 45.7685C42.5342 47.1481 41.4094 48.2708 40.0263 48.2708H33.246C31.8629 48.2708 30.7381 47.1481 30.7381 45.7685ZM71.4852 38.4358H1.78713V6.83578C1.78713 5.24085 3.08821 3.94358 4.68686 3.94358H53.3199V13.9335C53.3199 14.6846 53.6959 15.3796 54.326 15.793C54.956 16.206 55.7449 16.2744 56.4386 15.9771L60.7136 14.1368L64.9894 15.9776C65.1488 16.046 65.3138 16.0903 65.4804 16.1203C65.5348 16.1301 65.5889 16.1311 65.6436 16.1368C65.7209 16.1449 65.7977 16.1603 65.8751 16.1603C65.9034 16.1603 65.9304 16.1493 65.9586 16.1482C66.0957 16.143 66.2293 16.1196 66.3634 16.0893C66.4257 16.0752 66.4882 16.0692 66.5493 16.0497C66.7414 15.9885 66.928 15.9068 67.1012 15.793C67.7312 15.3796 68.1073 14.6846 68.1073 13.9335V3.94358H68.5855C70.1842 3.94358 71.4852 5.24085 71.4852 6.83578V38.4358Z"
              fill="#FF6C00"
            />
          </svg>
        </div>
        <div className="detail__text">
          <p className="course_title">HOW TO COUNT FROM 1 - 10</p>
          <p className="subject">Mathematics</p>
        </div>
      </div>

      <div className="detail__table">
        <div className="table_title">
          <span>DETAILS</span>
        </div>

        <div className="table-data">
          <table className="content-table">
            <thead>
              <tr className="headers">
                <th>Teacher</th>
                <th>Duration</th>
                <th>Upload Date</th>
                <th>Number of Views</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data
                  .filter((item, idx) => idx < 1)
                  .map((item) => (
                    <tr>
                      <td>Aunty Grace Okon</td>
                      <td>12:00</td>
                      <td>12/10/2021</td>
                      <td>1,000</td>
                      <td></td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default HomeDetailPage;
