import React, { useState } from "react";
import "./dashboard-menu.styles.scss";
import { Link, withRouter } from "react-router-dom";

import DashboardIcon from "../../assets/images/dashboard-vector.png";
import UserIcon from "../../assets/images/user-icon.png";
import CoursesIcon from "../../assets/images/courses-icon.png";
import ClassesIcon from "../../assets/images/classes-icon.png";
import StreamIcon from "../../assets/images/stream-icon.png";
import SubIcon from "../../assets/images/sub-icon.png";
import TransactionIcon from "../../assets/icons/transactions-ic.svg";
import ActivityIcon from "../../assets/images/activities-icon.png";
import AuditIcon from "../../assets/images/audit-icon.png";
import SettingsIcon from "../../assets/images/setting-icon.png";
import RectangleIcon from "../../assets/images/rectangle.png";

const Menu = ({ match, isMobile, location }) => {
  const pathname = location.pathname;

  return (
    <div className="dashboard-menu">
      <div className="dashboard-logo">Dyeka</div>

      <ul className="list-container">
        <Link to={`${match.path}`}>
          <li className="menu-list">
            {
              pathname === "/dashboard" &&
              <img src={RectangleIcon} alt="block" className="block" />
            }

            <div className="item-cover">
              <img src={DashboardIcon} alt="dashboard-icon" className="icon" />
              <span className="item">Dashboard</span>
            </div>
          </li>
        </Link>
        <Link to={`${match.path}/users`}>
          <li className="menu-list">
            {
              pathname.startsWith('/dashboard/users') &&
              <img src={RectangleIcon} alt="block" className="block" />
            }

            <div className="item-cover">
              <img src={UserIcon} alt="user-icon" className="icon" />
              <span className="item">Users</span>
            </div>
          </li>
        </Link>
        <Link to={`${match.path}/classes`}>
          <li className="menu-list">
            {
              pathname.startsWith('/dashboard/classes') &&
              <img src={RectangleIcon} alt="block" className="block" />
            }

            <div className="item-cover">
              <img src={ClassesIcon} alt="class-icon" className="icon" />
              <span className="item">Classes</span>
            </div>
          </li>
        </Link>
        <Link to={`${match.path}/livestream`}>
          <li className="menu-list">
            {
              pathname.startsWith('/dashboard/livestream') &&
              <img src={RectangleIcon} alt="block" className="block" />
            }

            <div className="item-cover">
              <img src={StreamIcon} alt="stream-icon" className="icon" />
              <span className="item">Live Stream</span>
            </div>
          </li>
        </Link>
        <Link to={`${match.path}/subscriptions`}>
          <li className="menu-list">
            {
              pathname.startsWith('/dashboard/subscriptions') &&
              <img src={RectangleIcon} alt="block" className="block" />
            }

            <div className="item-cover">
              <img src={SubIcon} alt="sub-icon" className="icon" />
              <span className="item">Subscriptions</span>
            </div>
          </li>
        </Link>

        <Link to={`${match.path}/transactions`}>
          <li className="menu-list">
            {
              pathname.startsWith('/dashboard/transactions') &&
              <img src={RectangleIcon} alt="block" className="block" />
            }

            <div className="item-cover">
              <img src={TransactionIcon} alt="transactions-icon" className="icon" />
              <span className="item">Transactions</span>
            </div>
          </li>
        </Link>

        <Link to={`${match.path}/activities`}>
          <li className="menu-list">
            {
              pathname.startsWith('/dashboard/activities') &&
              <img src={RectangleIcon} alt="block" className="block" />
            }

            <div className="item-cover">
              <img src={ActivityIcon} alt="activity-icon" className="icon" />
              <span className="item">Activities</span>
            </div>
          </li>
        </Link>
        <Link to={`${match.path}/audit-trail`}>
          <li className="menu-list">
            {
              pathname.startsWith('/dashboard/audit-trail') &&
              <img src={RectangleIcon} alt="block" className="block" />
            }

            <div className="item-cover">
              <img src={AuditIcon} alt="audit-icon" className="icon" />
              <span className="item">Audit Trail</span>
            </div>
          </li>
        </Link>
        <Link to={`${match.path}/settings`}>
          <li className="menu-list">
            {
              pathname.startsWith('/dashboard/settings') &&
              <img src={RectangleIcon} alt="block" className="block" />
            }

            <div className="item-cover">
              <img src={SettingsIcon} alt="setting-icon" className="icon" />
              <span className="item">Settings</span>
            </div>
          </li>
        </Link>
      </ul>
    </div>
  );
};

export default withRouter(Menu);
