const generateTableCol = (title, key, classes = '') => {
    return {title, key, classes}
}

const generateItem = (
    text,
    value,
    id = '',
    classes = '',
    url = '#',
    hasAction = false,
    action = null
) => {
    return {
        text,
        value,
        id,
        classes,
        url,
        hasAction,
        action
    }
}

const generateItemN = (
  {
      text,
      value,
      id = '',
      classes = '',
      url = '#',
      hasAction = false,
      action = null
  }
) => {
    return {
        text,
        value,
        id,
        classes,
        url,
        hasAction,
        action
    }
}

const termDropdown = () => {
    return {
        id: 'terms-select',
        title: 'Select Subject',
        items: [
            generateItem('First Term', 'first', '', '', ''),
            generateItem('Second Term', 'second', '', '', ''),
            generateItem('Third Term', 'third', '', '', ''),
        ]
    }
}

const question = () => {
    return {
        question: "",
        optional: false,
        editing: true,
        options: [
            {option: "", correct: false},
            {option: "", correct: false},
            {option: "", correct: false},
            {option: "", correct: false},
        ],
    }
}

export {generateItem, generateItemN, generateTableCol, termDropdown, question}