import React, {useEffect, useState} from "react";
import "./livestream-detail.styles.scss";
import {TopSectionNav} from "../../../../components/top-section-nav/top-section-nav";
import DetailIcon from "../../../../assets/icons/stream-ic.svg";
import {useParams, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import Api from "../../../../utils/action";
import {generateTableCol} from "../../../../utils/helper";
import DashboardTable from "../../../../components/general/dashboard-table/dashboard-table";
import {formatDate, formatFullDate} from "../../../../utils/global";
import {setLoading} from "../../../../redux/dashboard/actions";
import {toaster} from "evergreen-ui";

const LivestreamDetail = ({auth, setLoading}) => {
  const history = useHistory()
  const {id} = useParams();
  const [detail, switchDetail] = useState("users");
  const [livestreamDetail, setLivestreamDetail] = useState(null)
  const [users, setUsers] = useState([])
  const [usersTable, setUsersTable] = useState(null)
  const [livestreamDetailsTable, setLivestreamDetailsTable] = useState(null)

  const fetchLivestream = async () => {
    setLoading(true)

    try {
      const res = await Api.livestreams.fetchLivestream(auth, id)
      if (res.status) {
        setLivestreamDetail(res.data)
        setUsers(res.data.attendees.map(attendee => attendee.profiles))
      } else {
        toaster.danger(res.message)
        history.push('/dashboard/livestream')
      }
    } catch (e) {
      console.error(e)
    }

    setLoading(false)
  }

  useEffect(() => {
    fetchLivestream().then().catch(e => console.error(e))
  }, [auth, id])

  useEffect(() => {
    if (livestreamDetail) {
      const detailsTable = {
        id: 'livestream-details-table',
        cols: [
          generateTableCol('Tutor Name', 'tutor_name', 'no-wrap'),
          generateTableCol('Class', 'class_name', 'no-wrap'),
          generateTableCol('Event Time', 'event_start_time', 'no-wrap'),
        ],
        data: [
          {
            ...livestreamDetail,
            event_start_time: formatFullDate(new Date(livestreamDetail.event_start_time)),
            class_name: livestreamDetail.class ? livestreamDetail.class.name : ''
          },
        ]
      }

      setLivestreamDetailsTable(detailsTable)
    }

  }, [livestreamDetail])

  useEffect(() => {
    const usersTable = {
      id: 'attendees-table',
      cols: [
        generateTableCol('Name', 'full_name', 'no-wrap'),
        generateTableCol('Interest', 'formatted_interests'),
        generateTableCol('Date of Birth', 'date_of_birth', 'no-wrap'),
      ],
      data: users.map(user => {
        user.full_name = `${user.last_name} ${user.first_name}`
        user.formatted_interests = user.interests.join(', ')
        user.date_of_birth = formatDate(new Date(user.dob))

        return user
      })
    }

    setUsersTable(usersTable)
  }, [users])

  return (
    <div className="class__detail livestream_detail">
      <TopSectionNav
        path="/dashboard/livestream"
        header="Livestream"
        back="Back to Live Streams"
      />

      <div className="section-title-cover">
        <div className="section-title-block">
          <div className="img">
            <img src={DetailIcon} alt="detailIcon"/>
          </div>
          <p>{livestreamDetail && (livestreamDetail.title)}</p>
        </div>

        <div className="header-buttons">
          <a className="add-btn" href={`/dashboard/livestream/${id}/edit`}>
            <span className="btn-text mx-auto">EDIT</span>
          </a>

          {
            livestreamDetail &&
            (
              livestreamDetail.status.toLowerCase() === 'done' ?
                (
                  <dib className="add-btn" href="#">
                    <span className="btn-text mx-auto">LIVESTREAM COMPLETED</span>
                  </dib>
                ) :
                (
                  <a className="add-btn" href={`/dashboard/livestream/${id}/view`}>
                  <span className="btn-text mx-auto">
                    {
                      livestreamDetail.status.toLowerCase() === 'pending' ?
                        livestreamDetail.token && livestreamDetail.session_id
                        ? 'START LIVESTREAM' : 'INITIALIZE LIVESTREAM' : 'JOIN LIVESTREAM'
                    }
                  </span>
                  </a>
                )
            )
          }
        </div>
      </div>

      <div className="dashboard-card dashboard-tab-view">
        <div className="dashboard-tab-heading">
          <div className="dashboard-tab-menu">
            <div
              onClick={() => switchDetail("users")}
              className={`tab-menu-item${detail === "users" ? " active" : ""}`}
            >
              USERS
            </div>
            <div
              onClick={() => switchDetail("details")}
              className={`tab-menu-item${detail === "details" ? " active" : ""}`}
            >
              DETAILS
            </div>
          </div>
        </div>
        <div className="dashboard-tab-body">
          {
            usersTable &&
            detail === 'users' &&
            <div className="dashboard-table-cover">
              <DashboardTable
                columns={usersTable.cols}
                data={usersTable.data}
              />
            </div>
          }
          {
            livestreamDetailsTable &&
            detail === 'details' &&
            <div className="dashboard-table-cover">
              <DashboardTable
                columns={livestreamDetailsTable.cols}
                data={livestreamDetailsTable.data}
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({user}) => ({
  auth: user.currentAdmin.token,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LivestreamDetail));
