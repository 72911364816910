import React, { useState } from "react";
import "./notification.styles.scss";
import { TiArrowSortedDown } from "react-icons/ti";
import { connect } from "react-redux";
import { LogOut } from "../../redux/admin-user/admin.actions";
import { useHistory } from "react-router-dom";

const NotificationBar = ({ LogOut, currentAdmin }) => {
  const history = useHistory();
  const [logout, setLogout] = useState(false);
  // console.log(currentAdmin);

  const handleLogOut = () => {
      localStorage.clear();
      LogOut();
      history.push("/");
  };
    const gotoProfile = () => history.push('/dashboard/profile')

  return (
    <div className="notification">
        <div className="dropdown profile-dropdown-cover">
            <div className="dropdown-toggle" id="profile-dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <span>{currentAdmin.first_name}</span>
                <TiArrowSortedDown className="arrow" />
            </div>
            <ul className="dropdown-menu mt-2" aria-labelledby="#profile-dropdown-toggle">
                <li className="dropdown-item" id="active" onClick={gotoProfile}>Profile</li>
                <li className="dropdown-item" id="logout" onClick={handleLogOut}>Logout</li>
            </ul>
        </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  LogOut: () => dispatch(LogOut()),
});

const mapStateToProps = ({ user }) => ({
  currentAdmin: user.currentAdmin,
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBar);
