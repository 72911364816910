import ApiCalls from "./action/api";

const baseUrl = process.env.REACT_APP_ENV !== 'prod' ? 'https://api.test.dyeka.ng' : 'https://api.dyeka.ng';

const formatFullDate = (date, seconds = false, separator = '/') => {
    return `${formatDate(date, separator)} ${timeTo12HourFormat(date, seconds)}`
}

const formatFullDateInput = (date, seconds = false, separator = '/') => {
    if (typeof date === 'string') date = new Date(date)
    let formattedDate = `${date.getFullYear()}${separator}${pad(date.getMonth() + 1)}${separator}${pad(date.getDate())}`
    let formattedTime = formatTime(date)
    return `${formattedDate}T${formattedTime}`
}

const formatDate = (date, separator = '/') => {
    if (typeof date === 'string') date = new Date(date)
    return `${date.getDate()}${separator}${date.getMonth() + 1}${separator}${date.getFullYear()}`
}

const formatTime = (date, seconds = false) => {
    if (typeof date === 'string') date = new Date(date)

    if (seconds) {
        return `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`
    } else return `${pad(date.getHours())}:${pad(date.getMinutes())}`
}

const timeTo12HourFormat = (date, seconds = false) => {
    if (typeof date === 'string') date = new Date(date)
    const { hours, am } = getHoursObj(date.getHours())

    if (seconds) {
        return `${pad(hours)}:${pad(date.getMinutes())}:${pad(date.getSeconds())} ${am}`
    } else return `${pad(hours)}:${pad(date.getMinutes())} ${am}`
}

const formatActivity = (activities) => {
    activities.map(activity => {
        let date = new Date(activity.created_on)
        activity.date = `${formatDate(date)}, ${timeTo12HourFormat(date, true)}`
        activity.fullName = `${activity.owner_first_name} ${activity.owner_last_name}`

        return activity
    })

    return activities
}

const getHoursObj = (hours) => {
    if (hours === 12) {
        return {
            hours: 12,
            am: 'pm'
        }
    } else if (hours > 12) {
        return {
            hours: hours % 12,
            am: 'pm'
        }
    } else {
        return {
            hours: hours,
            am: 'am'
        }
    }
}

const pad = (val) => {
    return ('0' + val).slice(-2)
}

const previewImage = (id) => {
    let reader = new FileReader();
    reader.onload = function () {
        let output = document.querySelector(`#${id} .file-input-img-plc`);
        output.src = reader.result;
    }

    const fileInput = document.querySelector(`#${id} input[type="file"]`)
    if (fileInput && fileInput.files.length > 0) reader.readAsDataURL(fileInput.files[0])
}

const getSubjects = (auth, cb) => {
    ApiCalls.getFetchList(auth, 'admin/subjects', [], (res) => {
        if (res.status) {
            let subjects = []

            res.data.result.forEach(subject => {
                subjects.push({
                    id: subject.id,
                    name: subject.name
                })
            })

            cb(subjects)
        }
    })
}

const perPage = 20

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    s = s.toLowerCase()
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const formatCurrency = (value) => {
    value = Number(value)
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })
    return '₦' + formatter.format(parseFloat(value.toFixed(2)))
}

const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']

export {
    baseUrl,
    formatFullDate,
    formatDate,
    timeTo12HourFormat,
    formatActivity,
    formatFullDateInput,
    formatTime,
    perPage,
    previewImage,
    getSubjects,
    capitalize,
    formatCurrency,
    letters
}