import ApiCalls from "./api";
import {parse} from "query-string";

const getStats = async (auth, cb) => {
    const data = {
        activeUsers: 0,
        inactiveUsers: 0,
        totalUsers: 0,
        videos: 0,
        lessons: 0,
        profiles: 0,
    }

    const usersRes = await ApiCalls.getFetchListAsync(auth, 'admin/dashboard/users')
    if (usersRes.status) {
        const usersData = usersRes.data
        data.activeUsers = parseInt(usersData.active)
        data.inactiveUsers = parseInt(usersData.inactive)
        data.totalUsers = parseInt(usersData.total)
    }

    const videosRes = await ApiCalls.getFetchListAsync(auth, 'admin/dashboard/videos')
    data.videos = parseInt(videosRes.data.count)

    const lessonsRes = await ApiCalls.getFetchListAsync(auth, 'admin/dashboard/lessons')
    data.lessons = parseInt(lessonsRes.data.count)

    const profilesRes = await ApiCalls.getFetchListAsync(auth, 'admin/dashboard/profiles')
    data.profiles = parseInt(profilesRes.data.profiles)

    return data
}

const Dashboard = {
    stats: getStats,
}

export default Dashboard