import {
  CustomInput,
  CustomSelectInput,
  CustomTextarea,
} from "../form-elements/custom-input";
import React, { useEffect, useState } from "react";
import { Spinner, toaster } from "evergreen-ui";
import Api from "../../utils/action";

const ClassModal = ({
  saveClassCb,
  closeModal,
  auth,
  subjects,
  classData,
  updateClass,
}) => {
  const [actionRunning, setActionRunning] = React.useState(false);

  const updateClassName = (e) =>
    updateClass({ ...classData, name: e.target.value });
  const updateDescription = (e) =>
    updateClass({ ...classData, description: e.target.value });
  const updateStartAge = (e) =>
    updateClass({ ...classData, start_age: e.target.value.slice(0, 2) });
  const updateEndAge = (e) =>
    updateClass({ ...classData, end_age: e.target.value.slice(0, 2) });
  const updateSubjects = (e) => {
    const subject = { id: e.target.value };
    let found = false;

    const selectedSubjects = classData.subjects.map((sbj) => {
      if (sbj.id === subject.id) {
        found = true;
        sbj.delete = !e.target.checked;
      }

      return sbj;
    });

    if (!found) selectedSubjects.push(subject);
    updateClass({ ...classData, subjects: selectedSubjects });
  };

  const saveClass = () => {
    if (actionRunning) return;
    else setActionRunning(true);

    const data = {
      name: classData.name,
      description: classData.description,
      start_age: classData.start_age,
      end_age: classData.end_age,
      subjects: classData.subjects,
    };

    if (!data.end_age || data.end_age === "") delete data.end_age;

    if (classData.id) {
      Api.classes.updateClass(auth, classData.id, data, async (res) => {
        if (res.status) {
          toaster.success("Class Updated");
          saveClassCb({ status: true });
        } else {
          toaster.danger(res.message);
          saveClassCb({ status: false });
        }

        setActionRunning(false);
      });
    } else {
      Api.classes.addClass(auth, data, async (res) => {
        if (res.status) {
          toaster.success("Class Added");
          saveClassCb({ status: true });
        } else {
          toaster.danger(res.message);
          saveClassCb({ status: false });
        }

        setActionRunning(false);
      });
    }
  };

  return (
    <div className="modal settings-modal class-modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title">
              {classData.id ? "UPDATE CLASS" : "ADD CLASS"}
            </p>
            <div className="close-btn" onClick={closeModal}>
              <span>X</span>
            </div>
          </div>
          <div className="modal-body">
            <CustomInput
              id="class-name"
              label="Class Name"
              placeholder="Name"
              value={classData.name}
              handleChange={updateClassName}
            />
            <CustomTextarea
              id="class-description"
              label="Description"
              placeholder="description"
              value={classData.description}
              handleChange={updateDescription}
            />
            <div className="row">
              <div className="col-sm-6">
                <CustomInput
                  id="start-age"
                  label="Start Age"
                  type="number"
                  placeholder="00"
                  value={classData.start_age}
                  handleChange={updateStartAge}
                  maxLength={2}
                />
              </div>
              <div className="col-sm-6">
                <CustomInput
                  id="end-age"
                  label="End Age"
                  type="number"
                  placeholder="00"
                  value={classData.end_age}
                  handleChange={updateEndAge}
                />
              </div>
            </div>
            <div className="check-group-cover">
              <label className="group-label">Subjects</label>
              <div className="checkboxes">
                {subjects &&
                  subjects.map((subject) => {
                    return (
                      <div
                        className="check-group col-auto mb-2"
                        key={subject.id}
                      >
                        <label>
                          <input
                            type="checkbox"
                            value={subject.id}
                            name="subjects"
                            onChange={updateSubjects}
                            checked={
                              !!classData.subjects.find(
                                (cs) =>
                                  parseInt(cs.id) === parseInt(subject.id) &&
                                  !cs.delete
                              )
                            }
                          />
                          {subject.name}
                        </label>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {!actionRunning ? (
              <button className="btn-primary submit-btn" onClick={saveClass}>
                {classData.id ? "UPDATE" : "ADD"}
              </button>
            ) : (
              <Spinner size={16} className="spinner" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassModal;
