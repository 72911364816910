import AdminActionTypes from "./admin.types";

const INITIAL_STATE = {
  currentAdmin: null,
  passwordResetStatus: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AdminActionTypes.SET_CURRENT_ADMIN:
      return {
        ...state,
        currentAdmin: action.payload,
      };

    case AdminActionTypes.LOG_OUT:
      return {
        ...state,
        currentAdmin: null,
      };
    case AdminActionTypes.PASSWORD_RESET:
      return {
        ...state,
        passwordResetStatus: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
