import React, { useEffect, useState } from "react";

import { useParams, withRouter } from "react-router-dom";
import { TopSectionNav } from "../../../../../components/top-section-nav/top-section-nav";

import DetailIcon from "../../../../../assets/icons/class-detail-icon.svg";
import { useQuery } from "react-query";
import Api from "../../../../../utils/action";
import { connect } from "react-redux";
import {
  capitalize,
  formatFullDate,
  perPage,
} from "../../../../../utils/global";
import DashboardTable from "../../../../../components/general/dashboard-table/dashboard-table";
import Paginate from "../../../../../components/paginate/paginate";
import {
  generateItem,
  generateItemN,
  generateTableCol,
} from "../../../../../utils/helper";
import { toaster } from "evergreen-ui";
import { setLoading } from "../../../../../redux/dashboard/actions";

const SubjectDetail = ({ currentAdmin, setLoading }) => {
  const { classId, subjectId } = useParams();
  const [lessons, setLessons] = useState([]);
  const [lessonsTable, setLessonsTable] = useState(null);
  const [lessonsPagination, setLessonsPagination] = useState({
    total: 1,
    totalItems: 1,
    page: 0,
    perPage,
  });

  const [tests, setTests] = useState([]);
  const [testsTable, setTestsTable] = useState(null);
  const [testsPagination, setTestsPagination] = useState({
    total: 1,
    totalItems: 1,
    page: 0,
    perPage,
  });

  const [classDetails, setClassDetails] = useState(null);
  const [subjectTab, updateSubjectTab] = useState("lessons");
  const [termsTab, updateTermsTab] = useState("first");

  const fetchClassDetails = async () => {
    setLoading(true);

    const res = await Api.classes.fetchClass(currentAdmin.token, classId);
    if (res.status) {
      setClassDetails(res.data.result);
    }

    setLoading(false);
  };

  const fetchLessons = async () => {
    setLoading(true);

    const res = await Api.lessons.fetch(
      currentAdmin.token,
      lessonsPagination.page,
      classId,
      subjectId,
      termsTab
    );
    if (res.status) {
      setLessons(res.data.result);
      setLessonsPagination((pagination) => ({
        ...pagination,
        total: res.data.page_info.total_pages,
        totalItems: res.data.page_info.total,
      }));
    }

    setLoading(false);
  };

  const fetchTests = async () => {
    setLoading(true);

    const res = await Api.tests.fetch(
      currentAdmin.token,
      testsPagination.page,
      classId,
      subjectId,
      termsTab
    );
    if (res.status) {
      setTests(res.data.result);
      setTestsPagination((pagination) => ({
        ...pagination,
        total: res.data.page_info.total_pages,
        totalItems: res.data.page_info.total,
      }));
    }

    setLoading(false);
  };

  const setLessonsPage = (page) => {
    setLessonsPagination((pagination) => ({ ...pagination, page: page }));
  };

  const setTestsPage = (page) => {
    setTestsPagination((pagination) => ({ ...pagination, page: page }));
  };

  const deleteLesson = async (id) => {
    setLoading(true);

    const res = await Api.lessons.delete(currentAdmin.token, id);
    if (res.status) {
      toaster.success("Lesson deleted");
      await fetchLessons();
    } else toaster.danger(res.message);

    setLoading(false);
  };

  const deleteTest = (id) => {
    setLoading(true);

    Api.tests.delete(currentAdmin.token, id, async (res) => {
      if (res.status) {
        toaster.success("Test deleted");
        await fetchTests();
      } else toaster.danger(res.message);
    });

    setLoading(false);
  };

  useEffect(() => {
    fetchClassDetails()
      .then()
      .catch((e) => console.error(e));
  }, [currentAdmin.token, classId]);

  useEffect(() => {
    fetchLessons()
      .then()
      .catch((e) => console.error(e));
  }, [
    currentAdmin.token,
    classId,
    subjectId,
    termsTab,
    lessonsPagination.page,
  ]);

  useEffect(() => {
    fetchTests()
      .then()
      .catch((e) => console.error(e));
  }, [currentAdmin.token, classId, subjectId, termsTab, testsPagination.page]);

  useEffect(() => {
    const lessonsTable = {
      id: "subject-lessons-table",
      cols: [
        generateTableCol("Title", "name", "no-wrap"),
        generateTableCol("Description", "description"),
        generateTableCol("Status", "draft", "no-wrap"),
        generateTableCol("Created", "created", "no-wrap"),
      ],
      data: lessons.map((lesson) => {
        lesson.created = formatFullDate(new Date(lesson.created_on));
        lesson.draft = lesson.draft = false ? "Unpublished" : "Published";
        lesson.dropdown = {
          id: `lesson-test-${subjectId}-menu`,
          title: "",
          items: [
            generateItemN({
              text: "View",
              value: "view",
              url: `/dashboard/classes/${classId}/${subjectId}/lesson/${lesson.id}/view`,
            }),
            generateItemN({
              text: "Edit",
              value: "edit",
              url: `/dashboard/classes/${classId}/${subjectId}/lesson/${lesson.id}/edit`,
            }),
            generateItemN({
              text: "Delete",
              value: "delete",
              classes: "text-danger",
              hasAction: true,
              action: () => deleteLesson(lesson.id),
            }),
          ],
        };

        return lesson;
      }),
    };
    setLessonsTable(lessonsTable);
  }, [lessons]);

  useEffect(() => {
    const testsTable = {
      id: "subject-tests-table",
      cols: [
        generateTableCol("Title", "title", "no-wrap"),
        generateTableCol("Description", "description"),
        generateTableCol("Status", "draft", "no-wrap"),
        generateTableCol("Created", "created", "no-wrap"),
      ],
      data: tests.map((test) => {
        test.created = formatFullDate(new Date(test.created_on));
        test.draft = test.draft = false ? "Unpublished" : "Published";
        test.dropdown = {
          id: `subject-test-${subjectId}-menu`,
          title: "",
          items: [
            generateItemN({
              text: "View",
              value: "view",
              url: `/dashboard/classes/${classId}/${subjectId}/test/${test.id}/view`,
            }),
            generateItemN({
              text: "Edit",
              value: "edit",
              url: `/dashboard/classes/${classId}/${subjectId}/test/${test.id}/edit`,
            }),
            generateItemN({
              text: "Delete",
              value: "delete",
              classes: "text-danger",
              hasAction: true,
              action: () => deleteTest(test.id),
            }),
          ],
        };

        return test;
      }),
    };
    setTestsTable(testsTable);
  }, [tests]);

  return (
    <div className="class-detail-page">
      <TopSectionNav
        path={`/dashboard/classes/${classId}`}
        header="Subject"
        back="Back to Class"
      />

      <div className="section-title-cover">
        <div className="section-title-block">
          <div className="img">
            <img src={DetailIcon} alt="detailIcon" />
          </div>
          <p>
            {classDetails &&
              `${capitalize(subjectId)}/${capitalize(classDetails.name)}`}
          </p>
        </div>
        <div className="action-buttons">
          <a
            className="add-btn"
            href={`/dashboard/classes/${classId}/${subjectId}/add-lesson`}
          >
            <span className="btn_text">ADD A LESSON</span>
            <span className="btn-plus">+</span>
          </a>

          <a
            className="add-btn"
            href={`/dashboard/classes/${classId}/${subjectId}/add-test`}
          >
            <span className="btn_text">ADD A TEST</span>
            <span className="btn-plus">+</span>
          </a>
        </div>
      </div>

      <div className="dashboard-card">
        <div className="dashboard-tab-view">
          <div className="dashboard-tab-heading">
            <div className="dashboard-tab-menu">
              <span
                onClick={() => updateSubjectTab("lessons")}
                className={`tab-menu-item${
                  subjectTab === "lessons" ? " active" : ""
                }`}
              >
                LESSONS
              </span>
              <span
                onClick={() => updateSubjectTab("test")}
                className={`tab-menu-item${
                  subjectTab === "test" ? " active" : ""
                }`}
              >
                TEST
              </span>
            </div>
          </div>
          <div className="dashboard-tab-body">
            <div className="dashboard-tab-view">
              <div className="dashboard-tab-heading">
                <div className="dashboard-tab-menu">
                  <div
                    onClick={() => updateTermsTab("first")}
                    className={`tab-menu-item${
                      termsTab === "first" ? " active" : ""
                    }`}
                  >
                    First
                  </div>
                  <div
                    onClick={() => updateTermsTab("second")}
                    className={`tab-menu-item${
                      termsTab === "second" ? " active" : ""
                    }`}
                  >
                    Second
                  </div>
                  <div
                    onClick={() => updateTermsTab("third")}
                    className={`tab-menu-item${
                      termsTab === "third" ? " active" : ""
                    }`}
                  >
                    Third
                  </div>
                </div>
              </div>
              <div className="dashboard-tab-body p-2">
                {subjectTab === "lessons" && lessonsTable && (
                  <div>
                    <DashboardTable
                      columns={lessonsTable.cols}
                      data={lessonsTable.data}
                      hasMenu={true}
                    />
                    <Paginate
                      currentPage={lessonsPagination.page}
                      totalPages={lessonsPagination.total}
                      setPage={setLessonsPage}
                    />
                  </div>
                )}
                {subjectTab === "test" && testsTable && (
                  <div>
                    <DashboardTable
                      columns={testsTable.cols}
                      data={testsTable.data}
                      hasMenu={true}
                    />

                    <Paginate
                      currentPage={testsPagination.page}
                      totalPages={testsPagination.total}
                      setPage={setTestsPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user }) => ({
  currentAdmin: user.currentAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubjectDetail)
);
