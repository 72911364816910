import types from './types'

export const startSession = (session) => ({
  type: types.CREATE_SESSION,
  payload: session
})

export const destroySession = () => ({
  type: types.DESTROY_SESSION,
  payload: null
})
