import AdminActionTypes from "./admin.types";

export const setCurrentAdmin = (admin) => ({
  type: AdminActionTypes.SET_CURRENT_ADMIN,
  payload: admin,
});

export const LogOut = () => ({
  type: AdminActionTypes.LOG_OUT,
});

export const PasswordReset = (message) => ({
  type: AdminActionTypes.PASSWORD_RESET,
  payload: message,
});
