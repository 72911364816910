import {useHistory, useParams, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import {TopSectionNav} from "../../../../components/top-section-nav/top-section-nav";
import SaveLivestreamForm from "../../../../components/livestream/save-livestream/save-livestream-form";
import Api from "../../../../utils/action";
import {produce} from "immer";
import {formatFullDateInput} from "../../../../utils/global";
import {toaster} from "evergreen-ui";
import {setLoading} from "../../../../redux/dashboard/actions";

const EditLivestream = ({auth, setLoading}) => {
  const history = useHistory();
  const {id} = useParams();
  const [livestream, setLivestream] = useState(null)

  const fetchLivestream = async () => {
    setLoading(true)
    const res = await Api.livestreams.fetchLivestream(auth, id)
    const livestream = res.data
    livestream.event_date = formatFullDateInput(livestream.event_start_time, false, '-')

    if (res.status) setLivestream(livestream)
    setLoading(false)
  }

  useEffect(() => {
    fetchLivestream().then().catch((e) => console.error(e))
  }, [auth, id])

  const updateLivestream = (key, val) => {
    setLivestream(livestream => {
      return produce(livestream, data => {
        data[key] = val
      })
    })
  }

  const savedLivestream = () => {
    toaster.success('Livestream updated successfully')
    history.push(`/dashboard/livestream/${id}`)
  }

  return (
    <div className="add-livestream-page">

      <TopSectionNav
        path={`/dashboard/livestream/${id}`}
        header="Livestream"
        back="Back to Live Stream"
      />

      <h1>EDIT LIVESTREAM</h1>

      {
        livestream &&
        <SaveLivestreamForm
          auth={auth}
          livestream={livestream}
          updateLivestream={updateLivestream}
          savedLivestream={savedLivestream}
        />
      }
    </div>
  )
}

const mapStateToProps = ({user}) => ({
  auth: user.currentAdmin.token,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(setLoading(loading)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditLivestream));
